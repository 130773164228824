import { User } from '../../users/models/User'
import { SurveyQuestionDTO } from './SurveyQuestionDTO'

export class SurveyQuestion {
  private readonly _id: string | undefined
  private readonly _question: string
  private readonly _index: number
  private _selected: string

  constructor(p: SurveyQuestionDTO) {
    this._id = p.id
    this._question = p.question
    this._index = p.index
    this._selected = p.selected
  }

  get id(): string | undefined {
    return this._id
  }

  get question(): string {
    return this._question
  }

  get index(): number {
    return this._index
  }

  get selected(): string {
    return this._selected
  }

  set selected(s: string) {
    this._selected = s
  }
}

export interface CreateSurvey {
  date: Date
  userID: string
  questions: SurveyQuestionDTO[]
}

export interface UserSurvey {
  id: string
  userID: string
  user: User
  date: Date
}
