import i18n from '../../../i18n/i18n'

export enum ActivityType {
  FileUpload = 0,
  MedicalVisualization,
  Inivitation,
  FileUpdate,
  FailedFileUpload,
  FailedMedicalVisualization,
  FailedInivitation,
  FailedFileUpdate,
}

export const activityTypes = (): Record<ActivityType, string> => ({
  [ActivityType.FileUpload]: i18n.t('fileUploadActivity'),
  [ActivityType.MedicalVisualization]: i18n.t('medicalVisualizationAcitivity'),
  [ActivityType.Inivitation]: i18n.t('invitationActivity'),
  [ActivityType.FileUpdate]: i18n.t('fileUpdateActivity'),
  [ActivityType.FailedFileUpload]: i18n.t('failedFileUploadActivity'),
  [ActivityType.FailedMedicalVisualization]: i18n.t(
    'failedMedicalVisualizationActivity',
  ),
  [ActivityType.FailedInivitation]: i18n.t('failedInvitationActivity'),
  [ActivityType.FailedFileUpdate]: i18n.t('failedFileUpdateActivity'),
})
