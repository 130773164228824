import { Container, ContainerItem } from '../common/container/Container'
import { SurveyQuestionService } from '../modules/survey/services/SurveyQuestionService'
import {
    SURVEY_QUESTION_API_KEY,
    SURVEY_QUESTION_MODULE,
    SURVEY_QUESTION_SERVICE_KEY,
    SurveyQuestionProps,
} from '../modules/survey/container'
import { SurveyQuestionApi } from '../modules/survey/api/SurveyQuestionApi'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'

let container: Container

function init(p: SurveyQuestionProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(
    SURVEY_QUESTION_API_KEY,
    new ContainerItem({ instance: new SurveyQuestionApi() }),
  )

  items.set(
    SURVEY_QUESTION_SERVICE_KEY,
    new ContainerItem({
      instance: new SurveyQuestionService({ apiKey: SURVEY_QUESTION_API_KEY }),
    }),
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getSurveyQueryContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[SURVEY_QUESTION_MODULE],
      },
    })
  }

  return container
}
