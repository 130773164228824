import styles from './LoginRegister.module.scss'

import { Box, IconButton, Modal, Typography } from '@mui/material'
import Select from 'react-select'
import { RoleType } from '../../modules/users/enums/RoleEnum'

import playStore from '../../assets/home-view/playStore.svg'
import appleStore from '../../assets/home-view/appleStore.svg'
import arrowHome from '../../assets/home-view/arrowHome.svg'
import { CustomModal } from '../modal/CustomModal'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { AcceptTermsModal } from '../../features/register/AcceptTermsModal'

import { SatSurveyModal } from '../modal/SatSurveyModal'
import LoginRegisterController from './LoginRegister.controller'
import { ChangePasswordModal } from '../modal/ChangePasswordModal/ChangePasswordModal'
import { OTPVerificationModal } from '../modal/OTPVerificationModal/OTPVerificationModal'

export interface LoginRegisterProps {
  type: string
}

export const LoginRegister = (props: LoginRegisterProps) => {
  const {
    showSatisfactionSurvey,
    showChanguePassword,
    handlePasswordChangeSuccess,
    active,
    setActive,
    t,
    openModalAcceptTerms,
    handleModalAcceptTerms,
    handleModalCancelTerms,
    handleConsentAccepted,
    consentAccepted,
    openModalMustAcceptTerms,
    handleMustAcceptTerms,
    handlePressKey,
    registerUser,
    handleChangeRegister,
    optionsInvited,
    selectStyle,
    handleSelectedRole,
    options,
    selectedOption,
    setRegisterUser,
    setSelectedOption,
    setTermsAgreed,
    termsAgreed,
    setOpenModalAcceptTerms,
    handleRegister,
    login,
    setLogin,
    showPassword,
    setShowPassword,
    setMPasswordOpened,
    handleLogin,
    mpasswordOpened,
    emailPassword,
    setEmailPassword,
    handleSendRememberPassword,
    user2,
    navigate,
    roleDashboard,
    showOTPInput,
    handleCloseOTPModal,
    handleVerifyOTP,
  } = LoginRegisterController(props)
  return (
    <Box className={styles.loginContainer}>
      {(!showSatisfactionSurvey || !showChanguePassword) && (
        <Box className={styles.downloadApp}>
          {active === 'register' ? (
            <p className={styles.title0}>{t('creatAccount')}</p>
          ) : (
            <p className={styles.title0}>{t('patientLogin')}</p>
          )}
          <Box className={styles.line0}>
            <Box className={styles.line} />
          </Box>
          <img src={arrowHome} className={styles.arrow} />
          <Box className={styles.downloadBox}>
            <p className={styles.title}>{t('downloadAriaAppNow')}</p>
            <p className={styles.description}>
              {t('downloadAriaAppDescription')}
            </p>
            <Box className={styles.stores}>
              <img src={playStore} />
              <img src={appleStore} />
            </Box>
          </Box>
        </Box>
      )}
      {openModalAcceptTerms && (
        <Modal style={{ overflow: 'auto' }} open={openModalAcceptTerms}>
          <AcceptTermsModal
            saveRegister={handleModalAcceptTerms}
            onClose={handleModalCancelTerms}
            handleConsentAccepted={handleConsentAccepted}
            consentAccepted={consentAccepted}
          />
        </Modal>
      )}
      {openModalMustAcceptTerms && (
        <Modal style={{ overflow: 'auto' }} open={openModalMustAcceptTerms}>
          <CustomModal
            titleStyle={styles.titleStyleCustomModal}
            titleContentStyle={styles.titleContentStyle}
            title={t('checkSorry')}
            handleClose={handleMustAcceptTerms}
          >
            <Typography className={styles.textContactUsCustomModal}>
              {t('checkSorryContactUs')}
            </Typography>
          </CustomModal>
        </Modal>
      )}
      {(!showSatisfactionSurvey || !showChanguePassword) && (
        <>
          <Box className={styles.login}>
            <Box className={styles.tabs}>
              <Box
                className={styles.createAccount}
                onClick={() => {
                  setActive('register')
                }}
              >
                <p className={active === 'register' ? styles.active : ''}>
                  {t('createAccount')}
                </p>
              </Box>
              <Box
                className={styles.login2}
                onClick={() => {
                  setActive('login')
                }}
              >
                <p className={active === 'login' ? styles.active : ''}>
                  {t('login')}
                </p>
              </Box>
            </Box>

            {active === 'register' ? (
              <Box className={styles.registerBox}>
                <p className={styles.title}>{t('signUpForaFreeAccount')}</p>
                <Box className={styles.form}>
                  {/* <input
                    onKeyDown={handlePressKey}
                    type="text"
                    name="firstName"
                    className={styles.input}
                    placeholder={t('name')}
                    value={registerUser?.firstName || ''}
                    onChange={handleChangeRegister}
                  />
                  <input
                    onKeyDown={handlePressKey}
                    type="text"
                    name="lastName"
                    className={styles.input}
                    placeholder={t('lastName')}
                    value={registerUser?.lastName || ''}
                    onChange={handleChangeRegister}
                  /> */}
                  {/* <input
                    onKeyDown={handlePressKey}
                    type="text"
                    name="idDNI"
                    className={styles.input}
                    placeholder={t('ID')}
                    value={registerUser?.idDNI || ''}
                    onChange={handleChangeRegister}
                  /> */}
                  <input
                    onKeyDown={handlePressKey}
                    type="email"
                    name="email"
                    className={styles.input}
                    placeholder={t('email')}
                    value={registerUser?.email || ''}
                    onChange={handleChangeRegister}
                  />
                  <input
                    onKeyDown={handlePressKey}
                    type="password"
                    name="password"
                    className={styles.input}
                    placeholder={t('password')}
                    autoComplete="new-password"
                    value={registerUser?.password || ''}
                    onChange={handleChangeRegister}
                  />

                  <div className={styles.invitationCode}>
                    {registerUser?.invitationCode ? (
                      <>
                        <Select
                          options={optionsInvited}
                          styles={selectStyle}
                          value={{
                            value: registerUser?.role,
                            label: registerUser?.roleName,
                          }}
                          onChange={(v) => handleSelectedRole(v)}
                          isDisabled={true}
                        />
                      </>
                    ) : (
                      <>
                        <Select
                          options={options}
                          styles={selectStyle}
                          placeholder={t('role')}
                          value={selectedOption}
                          onChange={(v) => {
                            if (v) {
                              setRegisterUser({
                                ...registerUser,
                                role: Number(v.value),
                                roleName: v.label,
                              })
                              setSelectedOption(v)
                              setTermsAgreed(false)
                            }
                          }}
                        />
                      </>
                    )}
                  </div>
                  {registerUser?.invitationCode ? (
                    <></>
                  ) : (
                    <Box className={styles.agreeDiv}>
                      <Box className={styles.round}>
                        <input
                          type="checkbox"
                          id="checkbox"
                          checked={termsAgreed}
                          onChange={() => {
                            if (
                              registerUser?.role === RoleType.Patient &&
                              !registerUser.invitationCode
                            ) {
                              setOpenModalAcceptTerms(true)
                            } else {
                              setTermsAgreed(!termsAgreed)
                            }
                          }}
                        />
                        <label htmlFor="checkbox"></label>
                      </Box>
                      <label className={styles.agreeTerms} htmlFor="checkbox">
                        {t('agreeTerms')}
                      </label>
                    </Box>
                  )}

                  <Box
                    className={styles.registerButton}
                    onClick={handleRegister}
                  >
                    {t('createAnAccount')}
                  </Box>
                  <Box className={styles.container}></Box>
                </Box>
              </Box>
            ) : (
              <Box className={styles.loginBox}>
                <p className={styles.title}>{t('loginToYourAccount')}</p>
                <Box className={styles.form}>
                  <input
                    onKeyDown={handlePressKey}
                    type="text"
                    name="login_email"
                    value={login.login || ''}
                    className={styles.input}
                    placeholder={t('email')}
                    onChange={(v) => {
                      setLogin(
                        Object.assign({ ...login }, { login: v.target.value }),
                      )
                    }}
                  />
                  <div className={styles.input}>
                    <input
                      onKeyDown={handlePressKey}
                      type={showPassword ? 'text' : 'password'}
                      name="login_password"
                      value={login.password || ''}
                      className={styles.input2}
                      placeholder={t('password')}
                      autoComplete="new-password"
                      onChange={(v) => {
                        setLogin(
                          Object.assign(
                            { ...login },
                            { password: v.target.value },
                          ),
                        )
                      }}
                    />
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </div>
                  <p
                    className={styles.forgotPassword}
                    onClick={() => {
                      setMPasswordOpened(true)
                    }}
                  >
                    {t('forgotPassword')}
                  </p>
                  <Box className={styles.registerButton} onClick={handleLogin}>
                    {t('login')}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
      <Modal
        open={mpasswordOpened}
        onClose={() => {
          setMPasswordOpened(false)
        }}
      >
        <>
          <CustomModal
            title={''}
            handleClose={() => {
              setMPasswordOpened(false)
            }}
          >
            <Box className={styles.modalBox}>
              <h2 className={styles.text}>{t('rememberPassword')}</h2>
            </Box>

            <Box className={styles.inputbox}>
              <input
                className={styles.input}
                type="text"
                name="firstName"
                placeholder={t('email')}
                value={emailPassword}
                onChange={(e) => {
                  setEmailPassword(e.target.value)
                }}
              />
              <Box
                className={styles.button}
                onClick={handleSendRememberPassword}
              >
                {t('send')}
              </Box>
            </Box>
          </CustomModal>
        </>
      </Modal>
      {showSatisfactionSurvey && (
        <SatSurveyModal
          handleClose={() => {
            user2 && navigate(roleDashboard[Number(user2.roles)])
          }}
        />
      )}

      {showChanguePassword && (
        <ChangePasswordModal onClose={() => handlePasswordChangeSuccess()} />
      )}
      {showOTPInput && (
        <OTPVerificationModal
          onVerify={(e) => {
            handleVerifyOTP(e)
          }}
          onClose={handleCloseOTPModal}
        />
      )}
    </Box>
  )
}
