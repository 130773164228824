import CloseIcon from '@mui/icons-material/Close'
import styles from './ChangePasswordModal.module.scss'
import ChangePasswordController from './ChangePasswordModal.controller'

interface ChangePasswordModalProps {
  onClose: () => void
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  onClose,
}) => {
  const {
    t,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    error,
    handleSave,
    handleCloseModal,
  } = ChangePasswordController({ onClose })

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.agoraContainer}>
        <div className={styles.containerInside}>
          <div className={styles.contentIcon}>
            <CloseIcon className={styles.icon} onClick={handleCloseModal} />
          </div>
          <div className={styles.contentTitle}>
            <h2 className={styles.title}>{t('changePassword')}</h2>
            <p className={styles.description}>{t('changePassword3Months')}</p>
          </div>

          <div className={styles.containerForm}>
            <input
              type="password"
              placeholder={t('newPassword')}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className={styles.input}
            />
            <input
              type="password"
              placeholder={t('confirmPassword')}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={styles.input}
            />
          </div>

          {error && <div className={styles.alert}>{error}</div>}

          <div className={styles.buttons}>
            <button className={styles.buttonSave} onClick={handleSave}>
              {t('changePassword')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
