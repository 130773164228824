import { Container } from '../../common/container/Container'

export type SurveyQuestionContainerConfig = {
  moduleFullUrl: string
}

export type SurveyQuestionProps = {
  parentContainer: Container
  config: SurveyQuestionContainerConfig
}

export const SURVEY_QUESTION_MODULE = Symbol('SURVEY_QUESTION_MODULE')

export const SURVEY_QUESTION_API_KEY = Symbol('SURVEY_QUESTION_API_KEY')

export const SURVEY_QUESTION_SERVICE_KEY = Symbol('SURVEY_QUESTION_SERVICE_KEY')
