import styles from './Profile.module.scss'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import {
  fromModel,
  RegisterUser,
  UserDTO,
} from '../../modules/users/models/User'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { UserService } from '../../modules/users/services/UserService'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { useSnackbar } from 'notistack'
import { Box } from '@mui/material'
import { RoleType } from '../../modules/users/enums/RoleEnum'
import { useNavigate } from 'react-router-dom'
import { URL_DASHBOARD } from '../../routes/routes-constants'

const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export const Profile = () => {
  const { t } = useTranslation()
  const [user, setUser] = useState<UserDTO>()
  const [password, setPassword] = useState<string>('')
  const [passwordErrors, setPasswordErrors] = useState<string[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    userService.getByID(loggedUserService.get()?.id ?? '').subscribe((res) => {
      res && setUser(fromModel(res))
    })
  }, [])

  const validatePassword = (
    password: string,
  ): { isValid: boolean; errors: string[] } => {
    const errors: string[] = []

    if (password.length !== 0) {
      if (password.length < 10) {
        errors.push(t('passwordLength'))
      }
      if (!/[A-Z]/.test(password)) {
        errors.push(t('passwordUppercase'))
      }
      if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
        errors.push(t('passwordSpecial'))
      }
      if (!/[a-z]/.test(password)) {
        errors.push(t('passwordLowercase'))
      }
      if (!/[0-9]/.test(password)) {
        errors.push(t('passwordNumber'))
      }
    }

    return {
      isValid: errors.length === 0,
      errors,
    }
  }
  const handleChange = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setUser(Object.assign({ ...user }, { [e.target.name]: e.target.value }))
  }

  const handleSave = () => {
    if (!user?.email) {
      return
    }

    const validation = validatePassword(password)
    if (!validation.isValid) {
      setPasswordErrors(validation.errors)
      // setPassword('')
      enqueueSnackbar(t('passwordInvalid'), { variant: 'error' })
      return
    }

    const auxUser: RegisterUser = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      idDNI: user.idDni,
      email: user.email,
      password,
      language: user.language,
      role: RoleType.Patient,
      roleName: user.roleName,
      invitationCode: '',
      chatbotSessionID: '',
      createdAt: user.createdAt,
    }

    userService.update(auxUser).subscribe((res) => {
      if (res) {
        setPasswordErrors([])
        enqueueSnackbar(t('userCorrectlyUpdated'), { variant: 'success' })
      } else {
        setPasswordErrors([])
        enqueueSnackbar(t('anErrorHasOcurred'), { variant: 'error' })
      }
    })
  }
  const handleCancel = () => {
    navigate(URL_DASHBOARD)
  }

  const handlePressKey = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSave()
    }
  }

  return (
    <Box className={styles.profileC}>
      <Box className={styles.profileTitle}>
        <p className={styles.dashTitle}>{t('myProfile')}</p>
        <Box className={styles.linedashboard0}>
          <Box className={styles.linedashboard} />
        </Box>
        <img
          src={require('../../assets/images/arrow.png')}
          width={'200px'}
          height={'200px'}
          className={styles.arrow}
        />
      </Box>
      <Box className={styles.profileBox}>
        <Box className={styles.profileBox2}>
          <img
            src={require('../../assets/images/profile.png')}
            className={styles.imgProfile}
          />
          <p className={styles.dashTitle2}>{t('myProfile')}</p>
          <Box className={styles.form}>
            {/* <input
              onKeyDown={handlePressKey}
              type="text"
              name="firstName"
              className={styles.input}
              placeholder={t('name')}
              value={user?.firstName ?? ''}
              onChange={handleChange}
            />
            <input
              onKeyDown={handlePressKey}
              type="text"
              name="lastName"
              className={styles.input}
              placeholder={t('lastName')}
              value={user?.lastName ?? ''}
              onChange={handleChange}
            />
            <input
              onKeyDown={handlePressKey}
              type="text"
              name="idDNI"
              className={styles.input}
              placeholder={t('ID')}
              value={user?.idDni ?? ''}
              onChange={handleChange}
            /> */}
            <input
              onKeyDown={handlePressKey}
              readOnly
              type="email"
              name="email"
              style={{ paddingLeft: '4.5%' }}
              className={styles.input}
              placeholder={t('email')}
              value={user?.email ?? ''}
              onChange={handleChange}
            />
            <input
              onKeyDown={handlePressKey}
              type="password"
              name="password"
              className={styles.input}
              placeholder={t('password')}
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordErrors.length > 0 && (
              <Box className={styles.errorMessages}>
                <p className={styles.alert}>{passwordErrors[0]}</p>
              </Box>
            )}
            <Box className={styles.buttons}>
              <Box className={styles.buttonCancel} onClick={handleCancel}>
                {t('cancel')}
              </Box>
              <Box className={styles.buttonSave} onClick={handleSave}>
                {t('save')}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
