import { RouteProps } from '../../routes/AppRouter'
import styles from './Chatbot.module.scss'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { toModel } from '../../modules/chatbot/models/ChatbotQuestionDTO'
import { getChatbotContainer } from '../../container/chatbot-module'
import { ChatbotService } from '../../modules/chatbot/services/ChatbotService'
import { CHATBOT_SERVICE_KEY } from '../../modules/chatbot'

type Message = {
  from: string
  msg: string
}

const chatbotService =
  getChatbotContainer().get<ChatbotService>(CHATBOT_SERVICE_KEY)
export const Chatbot = (props: RouteProps) => {
  const { t } = useTranslation()
  const [messages, setMessages] = useState<Message[]>([])
  const [message, setMessage] = useState<string>('')
  const [showChatbot, setShowChatbot] = useState<boolean>(false)
  const scrollableDivRef = useRef(null)

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const sendMessage = () => {
    if (message === "") return
    const auxMessages = [...messages]
    auxMessages.push({
      msg: message,
      from: 'me',
    })
    setMessages([...auxMessages])
    setMessage('')

    const sessionID = sessionStorage.getItem('sessionChat')
    const languageSession = sessionStorage.getItem('language')

    const question = {
      session_id: sessionID ?? '',
      query: message,
      folder: 'default',
      locale: languageSession ?? 'es',
    }

    chatbotService.makeQuestion(toModel(question)).subscribe((res) => {
      if (!res) return
      if (!res.response) return

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages]
        updatedMessages.push({
          msg: res.response ?? '',
          from: 'chat',
        })
        return updatedMessages
      })

      setMessage('')
    })
  }

  const handlePressKey = (event: {
    key: string
    preventDefault: () => void
  }) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      sendMessage()
    }
  }

  function stripHtmlTags(html: string) {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent ?? ''
  }

  return (
    <Box className={styles.container}>
      {showChatbot ? (
        <Box className={styles.chatbot}>
          <Box className={styles.header}>
            <p className={styles.title}>Chatbot</p>
            <i
              className="fas fa-xmark icon"
              onClick={() => setShowChatbot(!showChatbot)}
            />
          </Box>
          <Box className={styles.scrollBox} ref={scrollableDivRef}>
            <p>{t('chatbotMessage')}</p>
            {messages.map((item, i) => (
              <Box key={item.msg + i}>
                {item.from === 'chat' ? (
                  <Box className={styles.patientBox}>
                    <img
                      src={require('../../assets/images/chaticon.png')}
                      width={30}
                      height={30}
                      className={styles.chaticon}
                    />
                    <Box className={styles.patientBox2}>
                      <img
                        src={require('../../assets/images/rectanglechatbot.png')}
                        width={30}
                        height={30}
                      />
                      <Box className={styles.patientboxMsg}>
                        <Typography className={styles.pre}>
                          {stripHtmlTags(item.msg)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box className={styles.patientBoxRight}>
                    <Box
                      style={{
                        width: window.innerWidth > 1490 ? '70px' : '121px',
                      }}
                    />
                    <Box className={styles.patientBoxRight2}>
                      <Box className={styles.patientboxMsgRight}>
                        {item.msg}
                      </Box>
                      <img
                        src={require('../../assets/images/rectangleme.png')}
                        width={30}
                        height={30}
                      />
                      <img
                        src={require('../../assets/images/chatmeicon.png')}
                        className={styles.chatmeicon}
                        width={30}
                        height={30}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          <Box className={styles.textInput}>
            <input
              onKeyDown={handlePressKey}
              type="text"
              value={message}
              placeholder={t('typeAmessage')}
              onChange={(e) => setMessage(e.target.value)}
            />
            <img
              onClick={sendMessage}
              src={require('../../assets/images/arrowchat.png')}
              width={23}
              height={23}
            />
          </Box>
        </Box>
      ) : (
        <img
          src={require('../../assets/images/chatbot.png')}
          className={styles.img}
          width={93}
          height={93}
          onClick={() => setShowChatbot(!showChatbot)}
        />
      )}
    </Box>
  )
}
