import styles from './DashboardResearcher.module.scss'

import { Box } from '@mui/material'
import { GenericChart } from '../../components/generic-chart/GenericChart'
import { UserCard } from '../../components/user-card/UserCard'
import useIsGuestWithoutRegister from '../../hooks/useIsGuestWithoutRegister'
import { GenericHeader } from '../../components/header/GenericHeader'
import useIsRegisteredGuest from '../../hooks/useIsRegisteredGuest'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { USER_SERVICE_KEY } from '../../modules/users'
import { User } from '../../modules/users/models/User'
import * as XLSX from 'xlsx'

const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export const DashboardResearcher = () => {
  const { isGuestWithoutRegister } = useIsGuestWithoutRegister()
  const { isRegisteredGuest } = useIsRegisteredGuest()

  const isGuest = isRegisteredGuest || isGuestWithoutRegister
  const { t } = useTranslation()

  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const exportToExcel = () => {
    if (users.length) {
      const data = users.map((user) => ({
        [t('userId')]: user.id,
        // 'First Name': user.firstName,
        // 'Last Name': user.lastName,
        // 'ID DNI': user.idDni,
        [t('email')]: user.email,
        [t('role')]: user.roleName,
        [t('language')]: user.language,
        [t('chatbotSessionId')]: user.chatbotSessionID,
        [t('participationConsentStatus')]: 'Yes',
        [t('createdAt')]: user.createdAt
          ? new Date(user.createdAt).toLocaleDateString()
          : '',
      }))

      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Users')

      XLSX.writeFile(wb, `users_export.xlsx`)
    }
  }

  useEffect(() => {
    userService.getAllUsers().subscribe(
      (result) => {
        setUsers(result.items)
        setLoading(false)
      },
      (err) => {
        console.error(err)
        setLoading(false)
      },
    )
  }, [userService])

  return (
    <Box className={styles.container}>
      <GenericHeader title={'general'} />
      <Box className={styles.dashboardContainer}>
        <Box className={styles.content}>
          {isGuest && (
            <Box>
              <UserCard />
            </Box>
          )}
          <Box className={styles.chartContainer}>
            <GenericChart />
            <Box className={styles.containerExportButton}>
              <Box className="buttonclose" onClick={exportToExcel}>
                {!loading ? t('exportUsersData') : '...'}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
