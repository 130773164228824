import {
  Box,
  Container,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
} from '@mui/material'
import style from './Survey.module.scss'
import { useTranslation } from 'react-i18next'
import initialQuestions from './SurveryQuestions'
import { useState } from 'react'
import { CreateSurvey } from '../../modules/survey/models/SurveyQuestion'
import { SurveyQuestionDTO } from '../../modules/survey/models/SurveyQuestionDTO'
import { getSurveyQueryContainer } from '../../container/survey-module'
import { getUserContainer } from '../../container/user-module'
import { SurveyQuestionService } from '../../modules/survey/services/SurveyQuestionService'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { SURVEY_QUESTION_SERVICE_KEY } from '../../modules/survey'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { useNavigate } from 'react-router-dom'
import {
  URL_DASHBOARD,
  URL_DASHBOARD_RESEARCHER,
  URL_PATIENT_LIST,
} from '../../routes/routes-constants'

const surveyService = getSurveyQueryContainer().get<SurveyQuestionService>(
  SURVEY_QUESTION_SERVICE_KEY,
)
const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)

export const Survey = () => {
  const { t } = useTranslation()
  const [questions, setQuestions] =
    useState<SurveyQuestionDTO[]>(initialQuestions)
  const user = loggedUserService.get()
  const navigate = useNavigate()
  const [error, setError] = useState<string>('')

  const roleDashboard: string[] = [
    '',
    URL_DASHBOARD,
    '',
    URL_DASHBOARD_RESEARCHER,
    URL_PATIENT_LIST,
  ]

  const handleSend = () => {
    if (!isValid()) {
      setError(t('allQuestionsRequired'))
      return
    } else {
      setError('')
    }

    const createSurvey: CreateSurvey = {
      date: new Date(),
      userID: user?.id ?? '',
      questions,
    }

    surveyService.add(createSurvey).subscribe(() => {
      user && navigate(roleDashboard[Number(user.roles)])
    })
  }

  const handleCheck = (index: number, value: string) => {
    const auxQuestions = [...questions]
    auxQuestions[index].selected = value
    setQuestions(auxQuestions)
  }

  const isValid = () => {
    return !questions.some((q) => q.selected === '')
  }

  return (
    <Modal open={true}>
      <div className={style.agoraContainer}>
        <Box className={style.containerInside}>
          {/*  <Box className={style.contentIcon}>
            <img
              className={style.icon}
              src={close}
              alt={'close'}
              onClick={() =>
                user && navigate(roleDashboard[Number(user.roles)])
              }
            />
          </Box> */}
          <form>
            <Box className={style.containerForm}>
              <Box className={style.contentTitle}>
                <p className={style.title}>
                  {t('ariaSatisfactionSurvey').toLocaleUpperCase()}
                </p>
                <p className={style.description2}>{t('ariaSS5')}</p>
                <p className={style.description2} style={{ marginTop: '2.5%' }}>
                  {t('ariaSS6')}
                </p>
              </Box>
            </Box>
            <Box className={style.questionsContainer}>
              {questions.map((question, index) => (
                <Box key={question.id} className={style.questionsContainer2}>
                  <p className={style.question}>
                    {question.index}. {t(question.question)}
                  </p>
                  <RadioGroup row className={style.radioGroup}>
                    <FormLabel component="legend" className={style.indications}>
                      {t('totallyDisagree')}
                    </FormLabel>
                    <FormControlLabel
                      value="1"
                      control={<Radio className={style.radioStyle} />}
                      label="1"
                      className={style.radioLabel}
                      onClick={() => handleCheck(index, '1')}
                      classes={{
                        root: style.radioContainer,
                      }}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio className={style.radioStyle} />}
                      label="2"
                      className={style.radioLabel}
                      onClick={() => handleCheck(index, '2')}
                      classes={{
                        root: style.radioContainer,
                      }}
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio className={style.radioStyle} />}
                      label="3"
                      className={style.radioLabel}
                      onClick={() => handleCheck(index, '3')}
                      classes={{
                        root: style.radioContainer,
                      }}
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio className={style.radioStyle} />}
                      label="4"
                      className={style.radioLabel}
                      onClick={() => handleCheck(index, '4')}
                      classes={{
                        root: style.radioContainer,
                      }}
                    />
                    <FormControlLabel
                      value="5"
                      control={<Radio className={style.radioStyle} />}
                      label="5"
                      className={style.radioLabel}
                      onClick={() => handleCheck(index, '5')}
                      classes={{
                        root: style.radioContainer,
                      }}
                    />
                    <FormLabel component="legend" className={style.indications}>
                      {t('totallyAgree')}
                    </FormLabel>
                  </RadioGroup>
                </Box>
              ))}
            </Box>
            <Container className={style.buttonsContainer}>
              <Box className={style.buttons}>
                {error !== '' && <p className={style.labelError}>*{error}</p>}
                <Box className={style.buttonSave} onClick={handleSend}>
                  {t('answerQuestionnaire')}
                </Box>
              </Box>
            </Container>
          </form>
        </Box>
      </div>
    </Modal>
  )
}
