import './App.css'
import Navbar from './components/Navbar/Navbar'
import { AppRouter } from './routes/AppRouter'
import { Chatbot } from './components/Chatbot/Chatbot'
import { SnackbarProvider } from 'notistack'
import { Box, Modal } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyGlobalContext } from './common/utils/MyGlobalContext'
import { useNavigate } from 'react-router-dom'
import useLoggedInUser from './hooks/useLoggedInUser'
import { Permission } from './common/enums/Permissions'
import SubNavBar from './components/subNavBar/SubNavBar'
import { Footer } from './components/footer/Footer'
import { CustomModal } from './components/modal/CustomModal'
import { UserProvider } from './common/utils/GlobalSelectedUser'
import { getChatbotContainer } from './container/chatbot-module'
import { ChatbotService } from './modules/chatbot/services/ChatbotService'
import { CHATBOT_SERVICE_KEY } from './modules/chatbot'

const chatbotService =
  getChatbotContainer().get<ChatbotService>(CHATBOT_SERVICE_KEY)

function App() {
  const [goodbyeModal, setGoodbyeModal] = useState<boolean>(false)
  const [globalCode, setGlobalCode] = useState<string>(() => {
    return localStorage.getItem('globalCode') ?? ''
  })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useLoggedInUser()

  const handleCloseModal = () => {
    navigate(`/register/${globalCode}`)
    setGoodbyeModal(false)
  }

  useEffect(() => {
    chatbotService.getSessionID().subscribe((response) => {
      if (!response?.sessionID) return
      sessionStorage.setItem('sessionChat', response?.sessionID)
    })
  }, [])
  useEffect(() => {
    if (globalCode) {
      localStorage.setItem('globalCode', globalCode)
    }
  }, [globalCode])
  return (
    <UserProvider>
      <MyGlobalContext.Provider
        value={{
          goodbyeModal,
          setGoodbyeModal,
          globalCode,
          setGlobalCode,
        }}
      >
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          maxSnack={10}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            style={{
              minHeight: '100vh',
            }}
          >
            <Box className="App" style={{ flex: 1 }}>
              <Navbar />
              {user?.permissions.includes(Permission.seeGuestSection) && (
                <SubNavBar />
              )}
              <AppRouter />
            </Box>
            <Chatbot />
            <Footer />
          </Box>
          <Modal open={goodbyeModal} onClose={handleCloseModal}>
            <>
              <CustomModal title={''} handleClose={handleCloseModal}>
                <Box className="modalBox">
                  <h2 className="text">{t('endOfSession')}</h2>
                </Box>
                <Box className="modalDescriptionBox">
                  <p className="text">{t('endInvitationSession')}:</p>
                  <a className="aclass" href={`/register/${globalCode}`}>
                    {window.location.origin}/register/{globalCode}
                  </a>
                </Box>
                <Box className="modal">
                  <Box className="buttonclose" onClick={handleCloseModal}>
                    {t('register')}
                  </Box>
                </Box>
              </CustomModal>
            </>
          </Modal>
        </SnackbarProvider>
      </MyGlobalContext.Provider>
    </UserProvider>
  )
}

export default App
