import { Box } from '@mui/material'
import style from './CustomModal.module.css'
import close from '../../assets/buttons/close.svg'
import React from 'react'

interface modalProps extends React.PropsWithChildren<{}> {
  title: string
  handleClose: () => void
  titleStyle?: string
  titleContentStyle?: string
}

export const CustomModal: React.FC<modalProps> = (props): JSX.Element => {
  return (
    <div>
      <div className={style.agoraContainer}>
        <Box className={style.containerInside}>
          <Box className={style.contentIcon}>
            <img
              className={style.icon}
              src={close}
              alt={'close'}
              onClick={props.handleClose}
            />
          </Box>
          <form>
            <Box className={style.containerForm}>
              <Box className={props.titleContentStyle ?? style.contentTitle}>
                <p className={props.titleStyle ?? style.title}>{props.title}</p>
                {props.children}
              </Box>
            </Box>
          </form>
        </Box>
      </div>
    </div>
  )
}
