import { Box, Modal } from '@mui/material'
import styles from './OTPVerificationModal.module.scss'
import { CustomModal } from '../CustomModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface OTPVerificationModalProps {
  onVerify: (code: string) => void
  onClose: () => void
}

export const OTPVerificationModal = ({
  onVerify,
  onClose,
}: OTPVerificationModalProps) => {
  const [code, setCode] = useState('')
  const { t } = useTranslation()

  return (
    <Modal open={true} onClose={onClose}>
      <CustomModal title={t('verifyOTP')} handleClose={onClose}>
        <Box className={styles.modalBox}>
          <input
            className={styles.input}
            type="text"
            maxLength={6}
            placeholder={t('enterOTPCode')}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />

          <div className={styles.buttons}>
            <button
              className={styles.buttonSave}
              onClick={() => onVerify(code)}
              type="button"
              disabled={code.length < 5}
            >
              {t('verify')}
            </button>
          </div>
        </Box>
      </CustomModal>
    </Modal>
  )
}
