export enum Permission {
  viewMedicalHistory = 'ViewMedicalHistory',
  uploadFileMedicalHistory = 'UploadFileMedicalHistory',
  editMedicalHistory = 'EditMedicalHistory',
  deleteMedicalHistory = 'DeleteMedicalHistory',
  uploadPhotoMedicalHistory = 'UploadPhotoMedicalHistory',
  usePatientChatbot = 'UsePatientChatbot',
  useGeneralChatbot = 'UseGeneralChatbot',
  viewPanel = 'ViewPanel',
  viewPatientDashboard = 'ViewPatientDashboard',
  viewGeneralDashboard = 'ViewGeneralDashboard',
  viewActivityHistory = 'ViewActivityHistory',
  invite = 'Invite',
  seeGuestSection = 'SeeGuestSection',
  seePatientList = 'SeePatientList',
  viewSatisfactionSurvey = 'ViewSatisfactionSurvey'
}
