import {
  URL_ABOUTUS,
  URL_ACTIVITY_HISTORY,
  URL_CHATBOT,
  URL_DASHBOARD,
  URL_DASHBOARD_RESEARCHER,
  URL_GENERAL_CHATBOT,
  URL_GUESTS,
  URL_INVITATION_CODE,
  URL_INVITE,
  URL_LOGGEDOUT,
  URL_LOGIN,
  URL_MEDICAL_RECORD,
  URL_PATIENT_LIST,
  URL_PRIVACY,
  URL_PROFILE,
  URL_REGISTER,
  URL_REGISTER_INVITATION,
  URL_SUPPORT,
  URL_SURVEY,
  URL_SATISFACTION_SURVEYS,
  URL_SURVEY_MOBILE
} from './routes-constants'
import { AU } from '../pages/aboutus'
import { DashboardScreen } from '../pages/dashboard'
import { ChatbotScreen } from '../pages/chatbot'
import { RegisterScreen } from '../pages/register'
import { LoginScreen } from '../pages/login'
import { ProfileScreen } from '../pages/profile'
import { LoggedOut } from '../pages/loggedout'
import { MedicalRecord } from '../pages/medicalRecord/MedicalRecord'
import { Route, Routes } from 'react-router-dom'
import { getUserContainer } from '../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../modules/users'
import { LoggedUserService } from '../modules/users/services/LoggedUserService'
import React from 'react'
import { InviteScreen } from '../pages/invite'
import { SupportScreen } from '../pages/support'
import { PrivacyCookies } from '../pages/privacyCookies/PrivacyCookies'
import { ActivityHistory } from '../pages/activityHistory/ActivityHistory'
import { InvitationCodeScreen } from '../pages/invitationCode'
import { Permission } from '../common/enums/Permissions'
import { GuestScreen } from '../pages/guest'
import { Dashboard } from '../pages/dashboard-researcher'
import { PatientList } from '../pages/patient-list/PatientList'
import useLoggedInUser from '../hooks/useLoggedInUser'
import { Survey } from '../pages/survey'
import { SatisfactionSurveys } from '../pages/satisfaction_surveys/SatisfactionSurveys'
import { SurveyMobile } from '../pages/surveyMobile'

export type RouteProps = {
  title?: string
  subtitle?: string
  color?: string
  id?: string
}

const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)

export const AppRouter: React.FC = () => {
  const { user } = useLoggedInUser()

  const publicRoutes = (
    <>
      <Route path={URL_LOGGEDOUT} element={<LoggedOut />} />
      <Route path={URL_ABOUTUS} element={<AU />} />
      <Route
        path={URL_GENERAL_CHATBOT}
        element={<ChatbotScreen type="general" />}
      />
      <Route path={URL_REGISTER} element={<RegisterScreen />} />
      <Route path={URL_LOGIN} element={<LoginScreen />} />
      <Route path={URL_PRIVACY} element={<PrivacyCookies />} />
      <Route path={URL_INVITATION_CODE} element={<InvitationCodeScreen />} />
      <Route path={URL_REGISTER_INVITATION} element={<RegisterScreen />} />
      <Route path={URL_GUESTS} element={<GuestScreen />} />
      <Route path={URL_SURVEY_MOBILE} element={<SurveyMobile />} />
    </>
  )

  const loggedRoutes = (
    <>
      {loggedUserService.userCan(Permission.viewPanel) && (
        <Route path={URL_LOGGEDOUT} element={<DashboardScreen />} />
      )}
      <Route path={URL_SURVEY_MOBILE} element={<SurveyMobile />} />
      <Route path={URL_LOGIN} element={<LoginScreen />} />
      {loggedUserService.userCan(Permission.viewPanel) && (
        <Route path={URL_DASHBOARD} element={<DashboardScreen />} />
      )}
      {loggedUserService.userCan(Permission.usePatientChatbot) && (
        <Route path={URL_CHATBOT} element={<ChatbotScreen type="personal" />} />
      )}
      {loggedUserService.userCan(Permission.viewGeneralDashboard) && (
        <Route path={URL_DASHBOARD_RESEARCHER} element={<Dashboard />} />
      )}
      {loggedUserService.userCan(Permission.useGeneralChatbot) && (
        <Route
          path={URL_GENERAL_CHATBOT}
          element={<ChatbotScreen type="general" />}
        />
      )}
      {loggedUserService.userCan(Permission.seePatientList) && (
        <Route path={URL_PATIENT_LIST} element={<PatientList />} />
      )}
      <Route path={URL_PROFILE} element={<ProfileScreen />} />
      <Route path={URL_MEDICAL_RECORD} element={<MedicalRecord />} />
      <Route path={URL_INVITE} element={<InviteScreen />} />
      <Route path={URL_SUPPORT} element={<SupportScreen />} />
      <Route path={URL_PRIVACY} element={<PrivacyCookies />} />
      {loggedUserService.userCan(Permission.viewActivityHistory) && (
        <Route path={URL_ACTIVITY_HISTORY} element={<ActivityHistory />} />
      )}
      <Route path={URL_INVITATION_CODE} element={<InvitationCodeScreen />} />
      <Route path={URL_REGISTER_INVITATION} element={<RegisterScreen />} />
      <Route path={URL_GUESTS} element={<GuestScreen />} />
      <Route path={URL_SURVEY} element={<Survey />} />
      {loggedUserService.userCan(Permission.viewSatisfactionSurvey) && (
        <Route path={URL_SATISFACTION_SURVEYS} element={<SatisfactionSurveys />} />
      )}
    </>
  )

  return <Routes> {user ? loggedRoutes : publicRoutes}</Routes>
}
