import { type LangType } from '../../../common/enums/LangType'
import { v4 as uuidv4 } from 'uuid'
import { type RoleType } from '../enums/RoleEnum'

export interface ChangePassword {
  olderPassword: string
  newPassword: string
  patientCode: string
}

export interface SendSMS {
  phone: string
  emailFrom: string
}

export interface RegisterUser {
  id: string
  firstName: string
  lastName: string
  idDNI: string
  email: string
  password: string
  language: string
  role: RoleType
  roleName: string
  invitationCode: string
  chatbotSessionID: string
  createdAt: Date
}

export interface UserChatbotSession {
  userID: string
  chatbotSessionID: string
}

export function emptyRegisterUser(language: string) {
  return {
    id: uuidv4(),
    firstName: '',
    lastName: '',
    idDNI: '',
    email: '',
    password: '',
    language,
    role: 1,
    roleName: 'Paciente',
    invitationCode: '',
    chatbotSessionID: '',
    createdAt: new Date(),
  }
}

export interface UserDTO {
  id: string
  firstName: string
  lastName: string
  idDni: string
  email: string
  roleName: string
  language: string
  chatbotSessionID: string
  createdAt: Date
  role: string
}

export function emptyUserDTO() {
  return {
    id: '',
    firstName: '',
    lastName: '',
    idDni: '',
    email: '',
    language: '',
    roleName: '',
    chatbotSessionID: '',
    createdAt: new Date(),
    role: ''
  }
}

export function fromModel(u: User): UserDTO {
  return {
    id: u.id,
    firstName: u.firstName,
    lastName: u.lastName,
    idDni: u.idDni,
    email: u.email,
    roleName: u.roleName,
    language: u.language,
    chatbotSessionID: u.chatbotSessionID,
    createdAt: u.createdAt,
    role: ''
  }
}

export class User {
  private readonly _id: string
  private _firstName: string
  private _lastName: string
  private readonly _idDni: string
  private _email: string
  private readonly _language: string
  private readonly _chatbotSessionID: string
  private _createdAt: Date
  private _roleName: string
  private _role: string

  constructor(p: UserDTO) {
    this._id = p.id
    this._firstName = p.firstName
    this._lastName = p.lastName
    this._idDni = p.idDni
    this._email = p.email
    this._language = p.language
    this._chatbotSessionID = p.chatbotSessionID
    this._createdAt = p.createdAt
    this._roleName = p.roleName
    this._role = p.role
  }

  get id(): string {
    return this._id
  }

  get chatbotSessionID(): string {
    return this._chatbotSessionID
  }

  get role(): string {
    return this._role
  }

  set firstName(value: string) {
    this._firstName = value
  }

  get firstName(): string {
    return this._firstName
  }

  get idDni(): string {
    return this._idDni
  }

  get language(): string {
    return this._language
  }

  get lastName(): string {
    return this._lastName
  }

  set lastName(value: string) {
    this._lastName = value
  }

  get email(): string {
    return this._email
  }

  set email(value: string) {
    this._email = value
  }

  get createdAt(): Date {
    return this._createdAt
  }

  set createdAt(value: Date) {
    this._createdAt = value
  }

  get roleName(): string {
    return this._roleName
  }

  set roleName(value: string) {
    this._roleName = value
  }

  toDTO(): UserDTO {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      idDni: this.idDni,
      email: this.email,
      roleName: this.roleName,
      chatbotSessionID: this.chatbotSessionID,
      language: this.language,
      createdAt: this._createdAt,
      role: this._role
    }
  }
}

export interface UserQuery {
  id: string
  ids: string[]
  firstName: string
  lastName: string
  centerID: string
  language: LangType
  dob: Date
  roleID: string
  active: boolean
  allFields: string
}

export function toModel(d: UserDTO): User {
  return new User(d)
}
