import { DocType } from '../enum/FileEnums'
import { FileDTO } from './FileDTO'

export class File {
  private readonly _id: string | undefined
  private _name: string
  private readonly _base64: string
  private readonly _type: string
  private readonly _docType: DocType // image, pdf, doc
  private readonly _ownerID: string
  private readonly _date: Date
  private readonly _extension: string
  private _ownerRole: string | undefined
  private readonly _content: string | undefined

  constructor(p: FileDTO) {
    this._id = p.id
    this._name = p.name
    this._base64 = p.base64
    this._type = p.type
    this._docType = p.docType
    this._ownerID = p.ownerID
    this._ownerRole = p.ownerRole
    this._date = p.date
    this._extension = p.extension
    this._content = p.content
  }

  get id(): string | undefined {
    return this._id
  }

  get base64(): string {
    return this._base64
  }

  get extension(): string {
    return this._extension
  }

  get type(): string {
    return this._type
  }

  get docType(): DocType {
    return this._docType
  }

  get name(): string {
    return this._name
  }

  set name(name: string) {
    this._name = name
  }

  get ownerID(): string {
    return this._ownerID
  }

  get date(): Date {
    return this._date
  }

  get ownerRole(): string | undefined {
    return this._ownerRole
  }

  set ownerRole(role: string | undefined) {
    this._ownerRole = role
  }

  get content(): string | undefined {
    return this._content
  }
}

export interface FileQuery {
  ownerID?: string
  name?: string
}
