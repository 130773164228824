import { useTranslation } from 'react-i18next'
import React from 'react'
import styles from './AcceptTermsModal.module.scss'
import style from '../../components/modal/CustomModal.module.css'
import { Box, Divider, Typography } from '@mui/material'
import {
  ButtonAccept,
  ButtonCancel,
} from '../../components/modal/styleMaterialUI'
import close from '../../assets/buttons/close.svg'

type AcceptTermsModalProps = {
  saveRegister: () => void
  onClose: () => void
  handleConsentAccepted: () => void
  consentAccepted: boolean
}

export const AcceptTermsModal: React.FC<AcceptTermsModalProps> = ({
  onClose,
  saveRegister,
  handleConsentAccepted,
  consentAccepted,
}) => {
  const { t } = useTranslation()

  return (
    <div
      style={{ paddingTop: '5%', paddingBottom: '5%' }}
      className={style.agoraContainer}
    >
      <Box className={styles.containerInside}>
        <Box className={style.contentIcon}>
          <img
            className={style.icon}
            src={close}
            alt={'close'}
            onClick={onClose}
          />
        </Box>
        <Box>
          <Typography className={styles.informed_consent}>
            {t('informed_consent')}
          </Typography>
          <Box className={styles.infoContainer}>
            <Box className={styles.contentText}>
              <Typography className={styles.keyText}>
                {t('promoterKey')}
              </Typography>
              <Typography className={styles.infoText}>
                {t('promoterInfo')}
              </Typography>
            </Box>
            <Box className={styles.contentText}>
              <Typography className={styles.keyText}>
                {t('project_managerKey')}
              </Typography>
              <Typography className={styles.infoText}>
                {t('project_managerInfo')}
              </Typography>
            </Box>
            <Box className={styles.contentText}>
              <Typography className={styles.infoText}>
                <span
                  style={{ fontWeight: 'normal' }}
                  className={styles.keyText}
                >
                  {t('legal_title1')}
                </span>{' '}
                {t('legal_text1')}
              </Typography>
            </Box>
            <Divider className={styles.divider} />
            <Typography className={styles.otherInfo}>
              {t('legal_text2')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text3')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text4')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text5')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text6')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text7')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text8')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title2')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text9')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title3')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text10')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title4')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text11')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title5')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text12')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title6')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text13')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title7')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text14')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text15')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title8')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text16')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title9')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text17')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title10')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text18')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text19')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text20')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title11')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text21')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text22')}
            </Typography>{' '}
            <Typography className={styles.otherInfo}>
              {t('legal_text23')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title12')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text24')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text25')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text26')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title13')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text27')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text28')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title14')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text29')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text30')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title15')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text31')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text32')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title16')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text33')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title17')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text34')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title18')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text37')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text38')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text39')}
            </Typography>
            <Typography className={styles.keyText}>
              {t('legal_title19')}
            </Typography>
            <Typography className={styles.otherInfo}>
              {t('legal_text40')}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.checkboxContainer}>
          <Box className={styles.round}>
            <input
              type="checkbox"
              id="simpleCheckbox"
              checked={consentAccepted}
              onChange={handleConsentAccepted}
            />
            <label htmlFor="simpleCheckbox"></label>
          </Box>
          <Typography className={styles.legalConsentText}>
            <label htmlFor="simpleCheckbox" style={{ cursor: 'pointer' }}>
              {' '}
              {t('legal_consent')}
            </label>
          </Typography>
        </Box>
        <Box className={styles.buttonContainer}>
          <ButtonCancel type={'button'} onClick={onClose}>
            {t('cancel')}
          </ButtonCancel>
          <ButtonAccept type={'button'} onClick={saveRegister}>
            {t('accept')}
          </ButtonAccept>
        </Box>
      </Box>
    </div>
  )
}
