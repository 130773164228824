import { CreateSurvey, SurveyQuestion, UserSurvey } from '../models/SurveyQuestion'
import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { ISurveyQuestionApi } from '../api/SurveyQuestionApi'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { SurveyQuestionDTO } from '../models/SurveyQuestionDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'
import { File } from '../../files/models/File'

type Props = {
  apiKey: symbol
}

export interface ISurveyQuestionService extends IInit {
  getByID(id: string): Observable<SurveyQuestion | undefined>

  getFilteredList(q: Query<SurveyQuestion>): Observable<SurveyQuestion[]>

  add(e: CreateSurvey): Observable<SurveyQuestion | undefined>

  update(e: SurveyQuestionDTO): Observable<SurveyQuestion | undefined>

  delete(id: string): Observable<boolean>

  getSatisfactionSurveyList(q: Query<SurveyQuestion>): Observable<ItemList<UserSurvey>>

  download(id: string): Observable<File | undefined>

  shouldSeeSatisfactionSurvey(id: string | null): Observable<boolean | undefined>
}

export class SurveyQuestionService implements ISurveyQuestionService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ISurveyQuestionApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ISurveyQuestionApi>(this._apiKey)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | null): Observable<SurveyQuestion | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<SurveyQuestion>): Observable<SurveyQuestion[]> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: CreateSurvey): Observable<SurveyQuestion | undefined> {
    return this._api.add(e)
  }

  update(e: SurveyQuestionDTO): Observable<SurveyQuestion | undefined> {
    return this._api.update(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getSatisfactionSurveyList(q: Query<SurveyQuestion>): Observable<ItemList<UserSurvey>> {
    return this._api.getSatisfactionSurveyList(q).pipe()
  }

  download(id: string): Observable<File | undefined> {
    return this._api.download(id)
  }

  shouldSeeSatisfactionSurvey(id: string): Observable<boolean | undefined> {
    return this._api.shouldSeeSatisfactionSurvey(id)
  }
}
