import { LoggedUser } from './LoggedUser'

export interface LoggedUserDTO {
  id: string
  firstName: string
  lastName: string
  createdAt: Date
  email: string
  phone: string
  sessionExpires: Date
  language: string
  cookieID: string
  roles: string
  permissions: string[]
  needsPasswordChange: boolean
}

export interface VerifyOTPResponse {
  success: boolean
  data?: LoggedUserDTO
  error?: string
}
export function toModel(d: LoggedUserDTO): LoggedUser {
  return new LoggedUser({
    id: d.id,
    firstName: d.firstName,
    lastName: d.lastName,
    createdAt: d.createdAt,
    email: d.email,
    phone: d.phone,
    sessionExpires: d.sessionExpires,
    language: d.language,
    roles: d.roles,
    cookieID: d.cookieID,
    permissions: d.permissions,
    needsPasswordChange: d.needsPasswordChange,
  })
}

export function fromModel(m: LoggedUser): LoggedUserDTO {
  return {
    id: m.id,
    firstName: m.firstName,
    lastName: m.lastName,
    createdAt: m.createdAt,
    email: m.email,
    phone: m.phone,
    sessionExpires: m.sessionExpires,
    language: m.language,
    roles: m.roles,
    cookieID: m.cookieID,
    permissions: m.permissions,
    needsPasswordChange: m.needsPasswordChange,
  }
}
