import { File } from '../models/File'
import {
  catchError,
  ConnectableObservable,
  map,
  multicast,
  Observable,
  of,
  Subject,
} from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IFileApi } from '../api/FileApi'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { AddImageResponse, FileDTO } from '../models/FileDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'

type Props = {
  apiKey: symbol
}

export interface IFileService extends IInit {
  getByID(id: string): Observable<File | undefined>

  getFilteredList(q: Query<File>): Observable<ItemList<File>>

  getAllByUserID(id: string | null): Observable<ItemList<File>>

  addImage(
    e: FileDTO,
    patientID: string,
  ): Observable<AddImageResponse | undefined>

  addFile(e: FileDTO): Observable<File | undefined>

  update(e: FileDTO): Observable<File | undefined>

  delete(id: string): Observable<boolean>

  getByUserID(id: string | null, q: Query<File>): Observable<ItemList<File>>
}

export class FileService implements IFileService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IFileApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IFileApi>(this._apiKey)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | null): Observable<File | undefined> {
    return this._api.getByID(id)
  }

  getByUserID(id: string | null, q: Query<File>): Observable<ItemList<File>> {
    return this._api.getByUserID(id, q)
  }

  getFilteredList(q: Query<File>): Observable<ItemList<File>> {
    return this._api.getFilteredList(q).pipe()
  }

  getAllByUserID(id: string | null): Observable<ItemList<File>> {
    return this._api.getAllByUserID(id)
  }

  addImage(e: FileDTO, patientID: string): Observable<AddImageResponse> {
    const multi = this._api.addImage(e, patientID).pipe(
      map((response) => ({
        success: true,
        data: response,
      })),
      catchError((error) =>
        of({
          success: false,
          error: error.message || 'Error desconocido',
        }),
      ),
      multicast(() => new Subject<AddImageResponse>()),
    ) as ConnectableObservable<AddImageResponse>

    multi.connect()
    multi.subscribe((response) => {
      if (response.success && response.data) {
        this._statusService.sendStatus({
          variant: 'success',
        })
      } else {
        this._statusService.sendStatus({
          variant: 'error',
          error: new Error(response.error ?? 'Error desconocido'),
        })
      }
    })

    return multi
  }

  addFile(e: FileDTO): Observable<File | undefined> {
    return this._api.addFile(e)
  }

  update(e: FileDTO): Observable<File | undefined> {
    return this._api.update(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }
}
