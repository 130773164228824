import { v4 as uuidv4 } from 'uuid'
import { SurveyQuestion } from "../../modules/survey/models/SurveyQuestion"
import { SurveyQuestionDTO, fromModel } from '../../modules/survey/models/SurveyQuestionDTO'

const initialQuestions: SurveyQuestionDTO[] = []
initialQuestions.push(fromModel(new SurveyQuestion({
    id: uuidv4(),
    question: 'likeToUseThisToolFrequentlyQ',
    index: 1,
    selected: '',
})))

initialQuestions.push(fromModel(new SurveyQuestion({
    id: uuidv4(),
    question: 'unnecessarilyComplexToolQ',
    index: 2,
    selected: '',
})))

initialQuestions.push(fromModel(new SurveyQuestion({
    id: uuidv4(),
    question: 'easyToUseToolQ',
    index: 3,
    selected: '',
})))

initialQuestions.push(fromModel(new SurveyQuestion({
    id: uuidv4(),
    question: 'expertPersonnelNecessaryQ',
    index: 4,
    selected: '',
})))

initialQuestions.push(fromModel(new SurveyQuestion({
    id: uuidv4(),
    question: 'manyContradictionsQ',
    index: 5,
    selected: '',
})))

initialQuestions.push(fromModel(new SurveyQuestion({
    id: uuidv4(),
    question: 'learnQuicklyQ',
    index: 6,
    selected: '',
})))

initialQuestions.push(fromModel(new SurveyQuestion({
    id: uuidv4(),
    question: 'tediousToolQ',
    index: 7,
    selected: '',
})))

initialQuestions.push(fromModel(new SurveyQuestion({
    id: uuidv4(),
    question: 'confidentUsingToolQ',
    index: 8,
    selected: '',
})))

initialQuestions.push(fromModel(new SurveyQuestion({
    id: uuidv4(),
    question: 'neededToKnowFewThingsBeforeQ',
    index: 9,
    selected: '',
})))

export default initialQuestions;