import { Container, IInit } from '../../../common/container/Container'
import {
  LoggedUserDTO,
  toModel,
  VerifyOTPResponse,
} from '../models/LoggedUserDTO'
import {
  catchError,
  ConnectableObservable,
  map,
  multicast,
  Observable,
  of,
  Subject,
} from 'rxjs'
import { AuthDTO, TwoFactorResponse } from '../models/AuthDTO'
import { LoggedUser } from '../models/LoggedUser'
import { IUserApi } from '../api/UserApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Permission } from '../../../common/enums/Permissions'
import { URL_LOGIN } from '../../../routes/routes-constants'

export interface ILoggedUserService extends IInit {
  login(a: AuthDTO): Observable<LoggedUserDTO | TwoFactorResponse | undefined>

  verifyOTP(a: AuthDTO): Observable<VerifyOTPResponse | undefined>

  loginGuest(code: string): Observable<LoggedUserDTO | undefined>

  logout(): void

  getObservable(): Observable<LoggedUser | undefined>

  get(): LoggedUser | undefined
}

export const LOGGED_USER_KEY = 'logged user'
export const SELECTED_USER_KEY = 'selectedUser'

type Props = {
  apiKey: symbol
}

export class LoggedUserService implements ILoggedUserService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserApi
  private _statusService!: IStatusService

  private _user: LoggedUser | undefined
  private readonly _userSubject = new Subject<LoggedUser | undefined>()

  constructor(p: Props) {
    this.loadUser()
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  login(a: AuthDTO): Observable<LoggedUserDTO | TwoFactorResponse | undefined> {
    const multi = this._api
      .login(a)
      .pipe(
        multicast(
          () => new Subject<LoggedUserDTO | TwoFactorResponse | undefined>(),
        ),
      ) as ConnectableObservable<LoggedUserDTO | TwoFactorResponse | undefined>
    multi.connect()
    multi.subscribe((response) => {
      if (response && 'id' in response) {
        // If it's LoggedUserDTO
        this.storeUser(response)
      }
    })
    return multi
  }

  verifyOTP(a: AuthDTO): Observable<VerifyOTPResponse> {
    const multi = this._api.verifyOTP(a).pipe(
      map((response) => ({
        success: true,
        data: response,
      })),
      catchError((error) =>
        of({
          success: false,
          error: error.message || 'Unknown error',
        }),
      ),
      multicast(() => new Subject<VerifyOTPResponse>()),
    ) as ConnectableObservable<VerifyOTPResponse>

    multi.connect()
    multi.subscribe((response) => {
      if (response.success && response.data) {
        this.storeUser(response.data)
      }
    })

    return multi
  }

  loginGuest(a: string): Observable<LoggedUserDTO | undefined> {
    const multi = this._api
      .loginGuest(a)
      .pipe(
        multicast(() => new Subject<LoggedUserDTO | undefined>()),
      ) as ConnectableObservable<LoggedUserDTO | undefined>
    multi.connect()
    multi.subscribe((dto) => {
      if (dto) {
        this.storeUserGuest(dto)
      }
    })
    return multi
  }

  logout() {
    window.location.replace(`${window.location.origin}${URL_LOGIN}`)
    this._api.logout().subscribe(() => {
      this.removeUser()
      sessionStorage.removeItem('needsPasswordChange')
      document.cookie = 'SID' + '=; Max-Age=-1; Expires=0'
    })
  }

  get(): LoggedUser | undefined {
    return this._user
  }

  getObservable(): Observable<LoggedUser | undefined> {
    return this._userSubject.pipe()
  }

  private next() {
    this._user && this._userSubject.next(this._user)
  }

  private loadUser() {
    const dto = (JSON.parse(localStorage.getItem(LOGGED_USER_KEY) ?? 'null') ||
      undefined) as LoggedUserDTO
    if (dto && new Date(dto.sessionExpires) > new Date()) {
      this._user = toModel(dto)
      this.next()
      setTimeout(
        () => this.logout(),
        new Date(this._user.sessionExpires).getTime() - new Date().getTime(),
      )
    }
  }

  private storeUser(dto: LoggedUserDTO) {
    if (dto) {
      dto.language = sessionStorage.getItem('language') as string
    }
    localStorage.setItem(LOGGED_USER_KEY, JSON.stringify(dto))
    this._user = toModel(dto)
    this.next()
    setTimeout(
      () => this.logout(),
      new Date(this._user.sessionExpires).getTime() - new Date().getTime(),
    )
  }

  private storeUserGuest(dto: LoggedUserDTO) {
    localStorage.setItem(LOGGED_USER_KEY, JSON.stringify(dto))
    this._user = toModel(dto)
    this.next()
  }

  private removeUser() {
    localStorage.removeItem(LOGGED_USER_KEY)
    localStorage.removeItem(SELECTED_USER_KEY)
    // sessionStorage.removeItem('language')
    this._user = undefined
    this._userSubject.next(this._user)
    this.next()
  }

  sendRecoverPassword(email: string, lang: string): Observable<any> {
    return this._api.sendRecoverPassword(email, lang)
  }

  userCan(perm: Permission): boolean {
    return this._user?.permissions.includes(perm) ?? false
  }
}
