import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { getUserContainer } from '../../../container/user-module'
import {
  LOGGED_USER_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../../modules/users'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { UserService } from '../../../modules/users/services/UserService'
import {
  fromModel,
  type UserDTO,
  type RegisterUser,
} from '../../../modules/users/models/User'
import { RoleType } from '../../../modules/users/enums/RoleEnum'

const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export interface ChangePasswordControllerProps {
  onClose: () => void
}

const ChangePasswordController = (props: ChangePasswordControllerProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [user, setUser] = useState<UserDTO | null>(null)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    const userId = loggedUserService.get()?.id
    if (!userId) return

    userService.getByID(userId).subscribe((res) => {
      if (res) setUser(fromModel(res))
    })
  }, [])

  const validatePassword = (
    password: string,
  ): { isValid: boolean; errors: string[] } => {
    const errors: string[] = []

    if (password.length < 10) {
      errors.push(t('passwordLength'))
    }
    if (!/[A-Z]/.test(password)) {
      errors.push(t('passwordUppercase'))
    }
    if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
      errors.push(t('passwordSpecial'))
    }
    if (!/[a-z]/.test(password)) {
      errors.push(t('passwordLowercase'))
    }
    if (!/[0-9]/.test(password)) {
      errors.push(t('passwordNumber'))
    }

    return {
      isValid: errors.length === 0,
      errors,
    }
  }

  const handleSave = () => {
    if (!user) return

    if (newPassword !== confirmPassword) {
      setError(t('passwordMismatch'))
      return
    }

    const { isValid, errors } = validatePassword(newPassword)
    if (!isValid) {
      setError(errors[0])
      return
    }

    const updatedUser: RegisterUser = {
      ...user,
      idDNI: user.idDni,
      password: newPassword,
      role: RoleType.Patient,
      invitationCode: '',
      chatbotSessionID: '',
    }

    userService.update(updatedUser).subscribe(
      (res) => {
        if (res) {
          enqueueSnackbar(t('userCorrectlyUpdated'), { variant: 'success' })
          props.onClose()
        } else {
          enqueueSnackbar(t('passwordAlreadyUsed'), { variant: 'error' })
        }
      },
      () => enqueueSnackbar(t('anErrorHasOcurred'), { variant: 'error' }),
    )
  }
  const handleCloseModal = () => {
    props.onClose()
    loggedUserService.logout()
  }

  return {
    t,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    error,
    handleSave,
    handleCloseModal,
  }
}

export default ChangePasswordController
