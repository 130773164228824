import { Box, Typography } from '@mui/material'
import styles from './UserCard.module.scss'
import { useTranslation } from 'react-i18next'
import useLoggedInUser from '../../hooks/useLoggedInUser'
import Divider from '@mui/material/Divider'
import { useUser } from '../../common/utils/GlobalSelectedUser'
import useIsRegisteredGuest from '../../hooks/useIsRegisteredGuest'
import useIsGuestWithoutRegister from '../../hooks/useIsGuestWithoutRegister'

export const UserCard = () => {
  const { t } = useTranslation()
  const { user, infoAdditional } = useLoggedInUser()
  const { selectedUser } = useUser()
  const { isRegisteredGuest } = useIsRegisteredGuest()
  const { isGuestWithoutRegister } = useIsGuestWithoutRegister()
  const isGuest = isRegisteredGuest || isGuestWithoutRegister
  return (
    <Box className={styles.profileCard}>
      <Box className={styles.profileBox}>
        <Box className={styles.profileBox2}>
          <img
            src={require('../../assets/images/profile.png')}
            className={styles.imgProfile}
          />
          <p className={styles.dashTitle2}>{t('patientProfile')}</p>
          <Box className={styles.form}>
            {!isGuest && (
              <>
                <Box className={styles.formContent}>
                  <Typography className={styles.formTitle}>
                    {t('name')}
                  </Typography>
                  <Typography className={styles.formUserInfo}>
                    {selectedUser?.firstName ?? user?.firstName}
                  </Typography>
                  <Divider className={styles.divider} />
                </Box>
                <Box className={styles.formContent}>
                  <Typography className={styles.formTitle}>
                    {t('lastName')}
                  </Typography>
                  <Typography className={styles.formUserInfo}>
                    {selectedUser?.lastName ?? user?.lastName}
                  </Typography>
                  <Divider className={styles.divider} />
                </Box>
              </>
            )}
            <Box className={styles.formContent}>
              <Typography className={styles.formTitle}>{t('email')}</Typography>
              <Typography className={styles.formUserInfo}>
                {selectedUser?.email ?? user?.email}
              </Typography>
              <Divider className={styles.divider} />
            </Box>
            <Box className={styles.formContent}>
              <Typography className={styles.formTitle}>{t('ID')}</Typography>
              <Typography className={styles.formUserInfo}>
                {selectedUser?.idDni ?? infoAdditional?.dni}
              </Typography>
              <Divider className={styles.divider} />
            </Box>
            <Box className={styles.formContent}>
              <Typography className={styles.formTitle}>
                {t('registrationDate')}
              </Typography>
              <Typography className={styles.formUserInfo}>
                {(selectedUser &&
                  new Date(selectedUser?.createdAt).toLocaleDateString()) ??
                  (infoAdditional &&
                    new Date(infoAdditional?.createdAt).toLocaleDateString())}
              </Typography>
              <Divider className={styles.divider} />
            </Box>
            <Box className={styles.formContent}>
              <Typography
                className={styles.formTitle}
                style={{ minHeight: 50 }}
              >
                {t('otherInfo')}
              </Typography>
              <Typography className={styles.formUserInfo}></Typography>
              <Divider className={styles.divider} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
