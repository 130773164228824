export const URL_LOGGEDOUT: string = '/'
export const URL_ABOUTUS: string = '/aboutus'
export const URL_CONTACT: string = '/contact'
export const URL_DASHBOARD: string = '/dashboard'
export const URL_CHATBOT: string = '/chatbot'
export const URL_GENERAL_CHATBOT: string = '/generalChatbot'
export const URL_REGISTER: string = '/register'
export const URL_LOGIN: string = '/login'
export const URL_PROFILE: string = '/profile'
export const URL_MEDICAL_RECORD: string = '/medicalRecord'
export const URL_INVITE: string = '/invite'
export const URL_SUPPORT: string = '/support'
export const URL_PRIVACY: string = '/privacy'
export const URL_ACTIVITY_HISTORY: string = '/activityHistory'
export const URL_INVITATION_CODE: string = '/invitationCode/:code'
export const URL_REGISTER_INVITATION: string = '/register/:code'
export const URL_GUESTS: string = '/guests'
export const URL_DASHBOARD_RESEARCHER: string = '/dashboard-researcher'
export const URL_SURVEY: string = '/survey'
export const URL_SURVEY_MOBILE: string = '/survey_mobile/:id'
export const URL_SATISFACTION_SURVEYS: string = '/satisfaction_surveys'

export const URL_PATIENT_LIST: string = '/patient-list'
