import { Box, Container } from '@mui/material'
import style from './SatSurvey.module.css'
import close from '../../assets/buttons/closeBlue.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { URL_SURVEY } from '../../routes/routes-constants'

interface modalProps extends React.PropsWithChildren<{}> {
  handleClose: () => void
}

export const SatSurveyModal: React.FC<modalProps> = (props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={style.agoraContainer}>
      <Box className={style.containerInside}>
        <Box className={style.contentIcon}>
          <img
            className={style.icon}
            src={close}
            alt={'close'}
            onClick={props.handleClose}
          />
        </Box>
        <form>
          <Box className={style.containerForm}>
            <Box className={style.contentTitle}>
              <p className={style.title}>
                {t('ariaSatisfactionSurvey').toLocaleUpperCase()}
              </p>
              <p className={style.description}>{t('ariaSS1')}</p>
              <p className={style.description}>{t('ariaSS2')}</p>
              <p className={style.description}>{t('ariaSS3')}</p>
              <p className={style.description}>{t('ariaSS4')}</p>
            </Box>
            <Container className={style.buttonsContainer}>
              <Box className={style.buttons}>
                <Box
                  className={style.buttonSave}
                  onClick={() => navigate(URL_SURVEY)}
                >
                  {t('answerQuestionnaire')}
                </Box>
              </Box>
            </Container>
          </Box>
        </form>
      </Box>
    </div>
  )
}
