import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import styles from './Contact.module.scss'

interface FormData {
  firstName: string
  lastName: string
  email: string
  message: string
}

interface FormErrors {
  firstName: string
  lastName: string
  email: string
  message: string
}

export const Contact = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  })
  const [errors, setErrors] = useState<FormErrors>({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  })

  const validateForm = (): boolean => {
    let isValid = true
    const newErrors: FormErrors = {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    }

    if (!formData.firstName.trim()) {
      newErrors.firstName = t('nameRequired')
      isValid = false
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = t('lastNameRequired')
      isValid = false
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!formData.email.trim()) {
      newErrors.email = t('emailRequired')
      isValid = false
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = t('emailInvalid')
      isValid = false
    }

    if (!formData.message.trim()) {
      newErrors.message = t('messageRequired')
      isValid = false
    }

    setErrors(newErrors)
    return isValid
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    try {
      setIsSubmitting(true)

      // Configuración básica para axios sin credenciales
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      // TODO
      const apiURL = 'https://staging.aria-healthcare.com/backend/'
      // Enviar datos al backend
      const response = await axios.post(
        // 'http://localhost:8000/contact',
        apiURL,
        formData,
        config,
      )

      // Verificar la respuesta
      if (response.status === 200) {
        // Mostrar mensaje de éxito
        enqueueSnackbar(t('contactSuccess'), { variant: 'success' })

        // Limpiar el formulario
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          message: '',
        })
      } else {
        // Si el servidor responde pero con un código no exitoso
        enqueueSnackbar(t('contactError'), { variant: 'error' })
      }
    } catch (error: any) {
      // Manejar errores específicos
      if (error.response) {
        // El servidor respondió con un código de estado fuera del rango 2xx
        const errorMessage = error.response.data?.message || t('contactError')
        enqueueSnackbar(errorMessage, { variant: 'error' })
      } else if (error.request) {
        // La petición fue hecha pero no se recibió respuesta
        enqueueSnackbar(t('contactErrorNoResponse'), { variant: 'error' })
      } else {
        // Error al configurar la petición
        enqueueSnackbar(t('contactError'), { variant: 'error' })
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <section className={styles.contact} aria-labelledby="contact-title">
      <div className={styles.contact__header}>
        <h1 id="contact-title" className={styles.contact__title}>
          {t('contactUS')}
        </h1>
        <div className={styles.contact__line} aria-hidden="true" />
      </div>

      <div className={styles.contact__content}>
        <div className={styles['contact__form-container']}>
          <p className={styles.contact__intro_text}>{t('contactTextInfo')}</p>

          <form
            className={styles.contact__form}
            onSubmit={handleSubmit}
            aria-label={t('contactForm')}
          >
            <div className={styles['contact__form-group--inline']}>
              <input
                id="firstName"
                type="text"
                name="firstName"
                className={styles.contact__input}
                placeholder={t('name')}
                value={formData.firstName}
                onChange={handleChange}
                disabled={isSubmitting}
                aria-invalid={!!errors.firstName}
                aria-describedby={
                  errors.firstName ? 'firstName-error' : undefined
                }
              />
              <input
                id="lastName"
                type="text"
                name="lastName"
                className={styles.contact__input}
                placeholder={t('lastName')}
                value={formData.lastName}
                onChange={handleChange}
                disabled={isSubmitting}
                aria-invalid={!!errors.lastName}
                aria-describedby={
                  errors.lastName ? 'lastName-error' : undefined
                }
              />
              <input
                id="email"
                type="email"
                name="email"
                className={styles.contact__input}
                placeholder={t('email')}
                value={formData.email}
                onChange={handleChange}
                disabled={isSubmitting}
                aria-invalid={!!errors.email}
                aria-describedby={errors.email ? 'email-error' : undefined}
              />
            </div>

            <div className={styles['contact__form-group']}>
              <textarea
                id="message"
                name="message"
                className={`${styles.contact__input} ${styles['contact__input--textarea']}`}
                placeholder={t('message')}
                value={formData.message}
                onChange={handleChange}
                disabled={isSubmitting}
                aria-invalid={!!errors.message}
                aria-describedby={errors.message ? 'message-error' : undefined}
              />
            </div>

            <button
              type="submit"
              className={styles.contact__button}
              disabled={isSubmitting}
              aria-busy={isSubmitting}
            >
              {isSubmitting ? t('sending') : t('send')}
            </button>
          </form>
        </div>

        <div className={styles['contact__info-column']}>
          {/* <h2 className={styles.contact__subheading}>{t('contactDetails')}</h2> */}
          <address className={styles['contact__contact-details']}>
            <p>
              <span aria-label={t('emailLabel')}>Email: </span>
              <a
                href="mailto:support-aria@evidenze.org"
                className={styles.contact__link}
              >
                support-aria@evidenze.org
              </a>
            </p>
            <p>
              <span aria-label={t('phoneLabel')}>Phone: </span>
              <a href="tel:+34 91 456 11 05" className={styles.contact__link}>
                +34 91 456 11 05
              </a>
            </p>
          </address>
        </div>
      </div>
    </section>
  )
}
