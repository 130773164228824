import {
  Box,
  Container,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
} from '@mui/material'
import style from './SurveyMobile.module.scss'
import { useTranslation } from 'react-i18next'
import initialQuestions from './SurveryQuestions'
import { useEffect, useState } from 'react'
import { CreateSurvey } from '../../modules/survey/models/SurveyQuestion'
import { SurveyQuestionDTO } from '../../modules/survey/models/SurveyQuestionDTO'
import { getSurveyQueryContainer } from '../../container/survey-module'
import { getUserContainer } from '../../container/user-module'
import { SurveyQuestionService } from '../../modules/survey/services/SurveyQuestionService'
import { SURVEY_QUESTION_SERVICE_KEY } from '../../modules/survey'
import { USER_SERVICE_KEY } from '../../modules/users'
import { RouteProps, useNavigate } from 'react-router-dom'
import { User } from '../../modules/users/models/User'
import { UserService } from '../../modules/users/services/UserService'
import { useParams } from 'react-router-dom'
import i18n from '../../i18n/i18n'
import { URL_LOGIN } from '../../routes/routes-constants'

const surveyService = getSurveyQueryContainer().get<SurveyQuestionService>(
  SURVEY_QUESTION_SERVICE_KEY,
)

const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export const SurveyMobile = (props: RouteProps) => {
  const { t } = useTranslation()
  const [questions, setQuestions] =
    useState<SurveyQuestionDTO[]>(initialQuestions)
  const [user, setUser] = useState<User>()
  const navigate = useNavigate()
  const [error, setError] = useState<string>('')
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    id &&
      userService.getByID(id).subscribe((res) => {
        if (res) {
          i18n.changeLanguage(res.language)
          setUser(res)
        }
      })
  }, [])

  const handleSend = () => {
    if (!isValid()) {
      setError(t('allQuestionsRequired'))
      return
    } else {
      setError('')
    }

    const createSurvey: CreateSurvey = {
      date: new Date(),
      userID: user?.id ?? '',
      questions,
    }

    surveyService.add(createSurvey).subscribe(() => {
      user && navigate(URL_LOGIN)
    })
  }

  const handleCheck = (index: number, value: string) => {
    const auxQuestions = [...questions]
    auxQuestions[index].selected = value
    setQuestions(auxQuestions)
  }

  const isValid = () => {
    return !questions.some((q) => q.selected === '')
  }

  return (
    <Modal open={true}>
      <div className={style.agoraContainer}>
        {user && (
          <Box className={style.containerInside}>
            <form>
              <Box className={style.containerForm}>
                <Box className={style.contentTitle}>
                  <p className={style.title}>
                    {t('ariaSatisfactionSurvey').toLocaleUpperCase()}
                  </p>
                  <p className={style.description2}>{t('ariaSS5')}</p>
                  <p
                    className={style.description2}
                    style={{ marginTop: '2.5%' }}
                  >
                    {t('ariaSS6')}
                  </p>
                </Box>
              </Box>
              <Box className={style.questionsContainer}>
                {questions.map((question, index) => (
                  <Box key={question.id} className={style.questionsContainer2}>
                    <p className={style.question}>
                      {question.index}. {t(question.question)}
                    </p>
                    <RadioGroup className={style.radioGroup}>
                      <Box display={'flex'}>
                        <FormControlLabel
                          value="1"
                          control={<Radio className={style.radioStyle} />}
                          label="1"
                          className={style.radioLabel}
                          onClick={() => handleCheck(index, '1')}
                          classes={{
                            root: style.radioContainer,
                          }}
                          labelPlacement="end"
                        />
                        <FormLabel
                          component="legend"
                          className={style.indications}
                        >
                          {t('totallyDisagree')}
                        </FormLabel>
                      </Box>
                      <FormControlLabel
                        sx={{ marginLeft: '4px' }}
                        value="2"
                        control={<Radio className={style.radioStyle} />}
                        label="2"
                        className={style.radioLabel}
                        onClick={() => handleCheck(index, '2')}
                        classes={{
                          root: style.radioContainer,
                        }}
                      />
                      <FormControlLabel
                        sx={{ marginLeft: '4px' }}
                        value="3"
                        control={<Radio className={style.radioStyle} />}
                        label="3"
                        className={style.radioLabel}
                        onClick={() => handleCheck(index, '3')}
                        classes={{
                          root: style.radioContainer,
                        }}
                      />
                      <FormControlLabel
                        sx={{ marginLeft: '5px' }}
                        value="4"
                        control={<Radio className={style.radioStyle} />}
                        label="4"
                        className={style.radioLabel}
                        onClick={() => handleCheck(index, '4')}
                        classes={{
                          root: style.radioContainer,
                        }}
                      />
                      <Box display={'flex'}>
                        <FormControlLabel
                          value="5"
                          control={<Radio className={style.radioStyle} />}
                          label="5"
                          className={style.radioLabel}
                          onClick={() => handleCheck(index, '5')}
                          classes={{
                            root: style.radioContainer,
                          }}
                          labelPlacement="end"
                        />
                        <FormLabel
                          component="legend"
                          className={style.indications}
                        >
                          {t('totallyAgree')}
                        </FormLabel>
                      </Box>
                    </RadioGroup>
                  </Box>
                ))}
              </Box>
              <Container className={style.buttonsContainer}>
                <Box className={style.buttons}>
                  {error !== '' && <p className={style.labelError}>*{error}</p>}
                  <Box className={style.buttonSave} onClick={handleSend}>
                    {t('answerQuestionnaire')}
                  </Box>
                </Box>
              </Container>
            </form>
          </Box>
        )}
      </div>
    </Modal>
  )
}
