import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { getUserContainer } from '../../container/user-module'
import { type LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import {
  TwoFactorResponse,
  type AuthDTO,
} from '../../modules/users/models/AuthDTO'
import { useSnackbar } from 'notistack'
import {
  emptyRegisterUser,
  type RegisterUser,
  UserChatbotSession,
} from '../../modules/users/models/User'
import { type UserService } from '../../modules/users/services/UserService'
import {
  URL_DASHBOARD,
  URL_DASHBOARD_RESEARCHER,
  URL_PATIENT_LIST,
} from '../../routes/routes-constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { RoleType, roleTypes } from '../../modules/users/enums/RoleEnum'
import i18n from '../../i18n/i18n'
import { getChatbotContainer } from '../../container/chatbot-module'
import { CHATBOT_SERVICE_KEY } from '../../modules/chatbot'
import { ChatbotService } from '../../modules/chatbot/services/ChatbotService'

import useLoggedInUser from '../../hooks/useLoggedInUser'
import axios from 'axios'
import { getSurveyQueryContainer } from '../../container/survey-module'
import { SurveyQuestionService } from '../../modules/survey/services/SurveyQuestionService'
import { SURVEY_QUESTION_SERVICE_KEY } from '../../modules/survey'
import { LoggedUserDTO } from '../../modules/users/models/LoggedUserDTO'

export interface LoginRegisterControllerProps {
  type: string
}
interface SelectStyles {
  control: (
    styles: Record<string, any>,
    state: { isFocused: boolean },
  ) => Record<string, any>
  option: (
    styles: Record<string, any>,
    state: { isFocused: boolean; isSelected: boolean },
  ) => Record<string, any>
  dropdownIndicator: (base: any) => Record<string, any>
}
const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)
const chatbotService =
  getChatbotContainer().get<ChatbotService>(CHATBOT_SERVICE_KEY)

const surveyService = getSurveyQueryContainer().get<SurveyQuestionService>(
  SURVEY_QUESTION_SERVICE_KEY,
)

const LoginRegisterController = (props: LoginRegisterControllerProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const pathname = location.pathname
  const initialValue =
    pathname === `/register/${roleTypes()[RoleType.Researcher]}`
      ? RoleType.Researcher
      : RoleType.Patient

  const [selectedOption, setSelectedOption] = useState({
    value: initialValue,
    label: roleTypes()[initialValue],
  })
  const [active, setActive] = useState<string>(props.type)
  const [emailPassword, setEmailPassword] = useState<string>('')
  const [login, setLogin] = useState<AuthDTO>({
    login: '',
    password: '',
  })
  const [registerUser, setRegisterUser] = useState<RegisterUser>({
    ...emptyRegisterUser(i18n.language),
    role: selectedOption.value,
    roleName: selectedOption.label,
  })
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false)
  const [consentAccepted, setConsentAccepted] = useState<boolean>(false)
  const [mpasswordOpened, setMPasswordOpened] = useState<boolean>(false)

  const [showPassword, setShowPassword] = useState(false)
  const [openModalAcceptTerms, setOpenModalAcceptTerms] = useState(false)
  const [openModalMustAcceptTerms, setOpenModalMustAcceptTerms] =
    useState(false)
  const navigate = useNavigate()
  const { user } = useLoggedInUser()
  const [ip, setIp] = useState('')
  const [showSatisfactionSurvey, setShowSatisfactionSurvey] =
    useState<boolean>(false)
  const [showChanguePassword, setShowChanguePassword] = useState<boolean>(false)
  const user2 = loggedUserService.get()

  const [showOTPInput, setShowOTPInput] = useState<boolean>(false)

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json')
        setIp(response.data.ip)
      } catch (error) {
        console.error('Error al obtener la IP:', error)
      }
    }
    fetchIp()
  }, [])

  const getOptions = () => [
    { value: Number(RoleType.Patient), label: roleTypes()[RoleType.Patient] },
    {
      value: Number(RoleType.Researcher),
      label: roleTypes()[RoleType.Researcher],
    },
  ]
  const options = getOptions()

  useEffect(() => {
    if (selectedOption) {
      const updatedOption = options.find(
        (opt) => opt.value === selectedOption.value,
      )
      if (updatedOption) {
        setSelectedOption(updatedOption)
      }
    }
    if (registerUser?.invitationCode && registerUser?.role !== undefined) {
      const updatedInvitedOption = optionsInvited.find(
        (opt) => opt.value === registerUser.role,
      )

      if (updatedInvitedOption) {
        setRegisterUser({
          ...registerUser,
          roleName: updatedInvitedOption.label,
        })
      }
    }
  }, [i18n.language])

  const getOptionsInvited = () => [
    // TODO default value
    // { value: Number(RoleType.Patient), label: t('doctor') },
    // { value: Number(RoleType.Patient), label: t('familiar') },
    {
      value: Number(RoleType.Patient),
      label: roleTypes()[RoleType.Patient],
    },
  ]

  const optionsInvited = getOptionsInvited()

  const handlePressKey = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      active === 'login' ? handleLogin() : handleRegister()
    }
  }

  const handleSelectedRole = (e: any) => {
    setRegisterUser(
      Object.assign(
        { ...registerUser },
        { role: Number(RoleType.Patient), roleName: e.label },
      ),
    )
  }

  const handleConsentAccepted = () => {
    setConsentAccepted(!consentAccepted)
  }

  const roleDashboard: string[] = [
    URL_DASHBOARD_RESEARCHER,
    URL_DASHBOARD,
    '',
    URL_DASHBOARD_RESEARCHER,
    URL_PATIENT_LIST,
  ]

  const handleLogin = () => {
    loggedUserService.login(login).subscribe((res) => {
      if (!res) {
        enqueueSnackbar(t('incorrectUserOrPassword'), { variant: 'error' })
        setLogin({
          login: '',
          password: '',
        })
        return
      }

      const isLoggedUserDTO = (
        response: LoggedUserDTO | TwoFactorResponse,
      ): response is LoggedUserDTO => {
        return 'id' in response && !('requiresOTP' in response)
      }

      if ('requiresOTP' in res && res.requiresOTP) {
        setShowOTPInput(true)
        enqueueSnackbar(t('otpSentToEmail'), { variant: 'info' })
        return
      }

      if (isLoggedUserDTO(res)) {
        handleLoginSuccess(res)
      }
    })
  }

  const handleVerifyOTP = (otpCode: string) => {
    const verifyData = {
      login: login.login,
      password: login.password,
      otpCode,
    }

    loggedUserService.verifyOTP(verifyData).subscribe({
      next: (response) => {
        if (!response.success) {
          switch (response.error) {
            case 'invalidOTP':
              enqueueSnackbar(t('invalidOTPCode'), { variant: 'error' })
              break
            case 'sessionNotFound':
              enqueueSnackbar(t('sessionExpired'), { variant: 'error' })
              break
            default:
              enqueueSnackbar(t('unknownError'), { variant: 'error' })
          }
          return
        }

        if (response.data) {
          handleLoginSuccess(response.data)
        }
      },
      error: (error) => {
        console.error('Unexpected error:', error)
        enqueueSnackbar(t('unexpectedError'), { variant: 'error' })
      },
    })
  }
  const handleCloseOTPModal = () => {
    setShowOTPInput(false)
  }

  const handleLoginSuccess = (res: LoggedUserDTO) => {
    if (res.needsPasswordChange) {
      sessionStorage.setItem('needsPasswordChange', 'true')
      setShowChanguePassword(true)
    } else {
      const roleTypeValue = parseInt(res.roles)

      chatbotService.getSessionID().subscribe((chatbotRes) => {
        if (
          roleTypeValue === RoleType.PatientGuest ||
          roleTypeValue === RoleType.Researcher
        ) {
          return
        }
        if (chatbotRes?.sessionID) {
          const chatbotSession: UserChatbotSession = {
            userID: res.id,
            chatbotSessionID: chatbotRes.sessionID,
          }
          userService.updateChatbotSession(chatbotSession).subscribe()
        }
      })

      if (roleTypeValue >= 1 && roleTypeValue <= 4) {
        if (
          roleTypeValue !== RoleType.SystemAdmin &&
          roleTypeValue !== RoleType.Guest
        ) {
          surveyService
            .shouldSeeSatisfactionSurvey(res.id)
            .subscribe((surveyRes) => {
              if (surveyRes) {
                setShowSatisfactionSurvey(true)
              } else {
                navigate(roleDashboard[roleTypeValue])
              }
            })
        } else {
          navigate(roleDashboard[roleTypeValue])
        }
      } else {
        navigate(roleDashboard[roleTypeValue])
      }
    }
  }

  const handlePasswordChangeSuccess = () => {
    sessionStorage.removeItem('needsPasswordChange')
    setShowChanguePassword(false)
    navigate(roleDashboard[1])
  }

  const saveRegisterUser = () => {
    userService.add(registerUser, ip, new Date()).subscribe((res) => {
      if (!res)
        return enqueueSnackbar(t('anErrorHasOcurred'), { variant: 'error' })

      if (typeof res === 'string') {
        return enqueueSnackbar(t(res), { variant: 'error' })
      } else {
        enqueueSnackbar(t('correctlyRegisteredUser'), { variant: 'success' })
        setTermsAgreed(false)
        setConsentAccepted(false)
        setActive('login')
      }
      setRegisterUser(emptyRegisterUser(i18n.language))
    })
  }

  const handleModalAcceptTerms = () => {
    setOpenModalAcceptTerms(false)
    setTermsAgreed(consentAccepted)
    if (!consentAccepted) {
      setOpenModalMustAcceptTerms(true)
    }
  }
  const handleModalCancelTerms = () => {
    setOpenModalAcceptTerms(false)
    setConsentAccepted(termsAgreed)
  }

  const validatePassword = (
    password: string,
  ): { isValid: boolean; errors: string[] } => {
    const errors: string[] = []

    if (password.length < 10) {
      errors.push(t('passwordLength'))
    }

    if (!/[A-Z]/.test(password)) {
      errors.push(t('passwordUppercase'))
    }

    if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
      errors.push(t('passwordSpecial'))
    }

    if (!/[a-z]/.test(password)) {
      errors.push(t('passwordLowercase'))
    }

    if (!/[0-9]/.test(password)) {
      errors.push(t('passwordNumber'))
    }

    return {
      isValid: errors.length === 0,
      errors,
    }
  }
  const handleRegister = () => {
    if (
      // !registerUser?.firstName ||
      // !registerUser?.lastName ||
      // !registerUser.idDNI ||
      !registerUser.email ||
      !registerUser.password ||
      (!registerUser?.invitationCode && !termsAgreed)
    ) {
      enqueueSnackbar(t('allFieldsAreRequired'), { variant: 'warning' })
      return
    }

    const passwordValidation = validatePassword(registerUser.password)
    if (!passwordValidation.isValid) {
      passwordValidation.errors.forEach((error) => {
        enqueueSnackbar(error, { variant: 'error' })
      })
      return
    }

    saveRegisterUser()
  }

  const handleChangeRegister = (e: any) => {
    setRegisterUser(
      Object.assign({ ...registerUser }, { [e.target.name]: e.target.value }),
    )
  }

  const handleSendRememberPassword = () => {
    // TODO add logic for translation email, add lang to localstorage
    const lang = user?.language ?? sessionStorage.getItem('language') ?? 'en'
    loggedUserService
      .sendRecoverPassword(emailPassword, lang)
      .subscribe((res) => {
        if (res !== undefined) {
          enqueueSnackbar(t('anErrorHasOcurred'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('emailSuccesfullySent'), { variant: 'success' })
        }
        setEmailPassword('')
        setMPasswordOpened(false)
      })
  }
  const selectStyle: SelectStyles = {
    control: (base, { isFocused }) => ({
      ...base,
      '&:hover': {
        border: 'solid 1px var(--GREEN-ARIA)',
        boxShadow: 'none',
      },
      border: 'solid 1px var(--GREEN-ARIA)',
      boxShadow: 'none',
      borderRadius: '18px',
      marginTop: '4%',
      textAlign: 'left',
    }),
    option: (base, { isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? 'var(--GREEN-ARIA)' : 'white',
        color: isFocused ? 'white' : 'black',
        boxShadow: 'none',
      }
    },
    dropdownIndicator: (base: any) => ({
      ...base,
      color: 'var(--GREEN-ARIA)',
      textAlign: 'left',
    }),
  }

  useEffect(() => {
    const code = window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1,
    )
    !Number.isNaN(Number(code)) &&
      setRegisterUser(
        Object.assign(
          { ...registerUser },
          { role: Number(RoleType.Patient), invitationCode: code },
        ),
      )
  }, [])

  useEffect(() => {
    const needsPasswordChange =
      sessionStorage.getItem('needsPasswordChange') === 'true'
    setShowChanguePassword(needsPasswordChange)
  }, [])
  const handleMustAcceptTerms = () => {
    setOpenModalMustAcceptTerms(!openModalMustAcceptTerms)
  }

  return {
    showSatisfactionSurvey,
    showChanguePassword,
    handlePasswordChangeSuccess,
    active,
    setActive,
    t,
    openModalAcceptTerms,
    handleModalAcceptTerms,
    handleModalCancelTerms,
    handleConsentAccepted,
    consentAccepted,
    openModalMustAcceptTerms,
    handleMustAcceptTerms,
    handlePressKey,
    registerUser,
    handleChangeRegister,
    optionsInvited,
    selectStyle,
    handleSelectedRole,
    options,
    selectedOption,
    setRegisterUser,
    setSelectedOption,
    setTermsAgreed,
    termsAgreed,
    setOpenModalAcceptTerms,
    handleRegister,
    login,
    setLogin,
    showPassword,
    setShowPassword,
    setMPasswordOpened,
    handleLogin,
    mpasswordOpened,
    emailPassword,
    setEmailPassword,
    handleSendRememberPassword,
    user2,
    navigate,
    roleDashboard,
    showOTPInput,
    handleCloseOTPModal,
    handleVerifyOTP,
  }
}

export default LoginRegisterController
