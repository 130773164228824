import { Box, Link, Divider } from '@mui/material'

import styles from './Footer.module.scss'
import { URL_PRIVACY } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Box className={styles.footerContainer} component={'footer'}>
      <Box className={styles.contentTexts}>
        <Link className={styles.footerText} href="#" id="contact">
          {t('contactUS')}
        </Link>
        <Divider className={styles.divider} />
        <Link
          onClick={async () => navigate(URL_PRIVACY)}
          className={styles.footerText}
          id="privacy"
        >
          {t('privacyCookies')}
        </Link>
      </Box>
    </Box>
  )
}
