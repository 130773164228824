import { CreateSurvey, SurveyQuestion, UserSurvey } from '../models/SurveyQuestion'
import { Container, IInit } from '../../../common/container/Container'
import { SurveyQuestionContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { SurveyQuestionDTO, toModel } from '../models/SurveyQuestionDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList, emptyList } from '../../../common/models/ItemList'
import { File } from '../../files/models/File'

export interface ISurveyQuestionApi extends IInit {
  getByID(id: string | null): Observable<SurveyQuestion | undefined>

  download(id: string | null): Observable<File | undefined> 

  getFilteredList(q: Query<SurveyQuestion>): Observable<SurveyQuestion[]>

  add(e: CreateSurvey): Observable<SurveyQuestion | undefined>

  update(e: SurveyQuestionDTO): Observable<SurveyQuestion | undefined>

  delete(id: string): Observable<boolean>

  getSatisfactionSurveyList(q: Query<SurveyQuestion>): Observable<ItemList<UserSurvey>>

  shouldSeeSatisfactionSurvey(id: string | null): Observable<boolean | undefined>
}

export class SurveyQuestionApi implements ISurveyQuestionApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (
      this._container.config as SurveyQuestionContainerConfig
    ).moduleFullUrl
  }

  getByID(id: string | null): Observable<SurveyQuestion | undefined> {
    return this._httpClient.get<SurveyQuestion>({ url: `${this._url}/` + id }).pipe(
      map<SurveyQuestionDTO, SurveyQuestion>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }

  getFilteredList(q: Query<SurveyQuestion>): Observable<SurveyQuestion[]> {
    return this._httpClient
      .get<SurveyQuestion[]>({ url: prepareURL(`${this._url}`, q) })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        }),
      )
  }

  add(e: CreateSurvey): Observable<SurveyQuestion | undefined> {
    return this._httpClient.post<SurveyQuestion>({ url: this._url, body: e }).pipe(
      map<SurveyQuestionDTO, SurveyQuestion>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }

  update(e: SurveyQuestionDTO): Observable<SurveyQuestion | undefined> {
    return this._httpClient.put<SurveyQuestion>({ url: this._url, body: e }).pipe(
      map<SurveyQuestionDTO, SurveyQuestion>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/` + id }).pipe(
      catchError((err) => {
        return of(err)
      }),
    )
  }

  getSatisfactionSurveyList(q: Query<SurveyQuestion>): Observable<ItemList<UserSurvey>> {
    return this._httpClient
      .get<ItemList<UserSurvey>>({ url: prepareURL(`${this._url+ '/list/satisfaction_survey'}`, q) })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<UserSurvey>())
        }),
      )
  }

  download(id: string): Observable<File | undefined> {
    return this._httpClient.get<File>({ url: `${this._url}/download/${id}` })
  }

  shouldSeeSatisfactionSurvey(id: string): Observable<boolean | undefined> {
    return this._httpClient.get<boolean>({ url: `${this._url}/shouldSeeSatisfactionSurvey/${id}` })
  }
}
