import { RouteProps } from '../../routes/AppRouter'
import { Box, Modal, Typography } from '@mui/material'
import './MedicalRecords.module.scss'
import { AppTable } from '../../components/table'
import { Field, SearchValue, Pager, Search } from '../../components/table/types'
import { useTranslation } from 'react-i18next'
import styles from './MedicalRecords.module.scss'
import React, { useEffect, useState } from 'react'
import { ActionsComponent } from '../../components/table/ActionsComponent'
import { Query, QueryParam, QueryParamN } from '../../common/api/Query'
import { UploadFile } from './UploadFile'
import { File, FileQuery } from '../../modules/files/models/File'
import { getUserContainer } from '../../container/user-module'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { useSnackbar } from 'notistack'
import { CustomModal } from '../../components/modal/CustomModal'
import pdfIcon from '../../assets/files/pdf.svg'
import imageIcon from '../../assets/files/imagen.svg'
import { useFileUpload } from '../../hooks/useFileUpload'
import { UserCard } from '../../components/user-card/UserCard'
import useIsGuestWithoutRegister from '../../hooks/useIsGuestWithoutRegister'
import style from '../../components/modal/CustomModal.module.css'
import {
  ButtonAccept,
  ButtonCancel,
  ButtonRegisterOrLogin,
} from '../../components/modal/styleMaterialUI'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../common/utils/MyGlobalContext'
import { GenericHeader } from '../../components/header/GenericHeader'
import useIsRegisteredGuest from '../../hooks/useIsRegisteredGuest'
import { useUser } from '../../common/utils/GlobalSelectedUser'
import { FileType } from '../../modules/files/enum/FileEnums'

const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

export const MedicalRecord = (props: RouteProps) => {
  // Hooks and services
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const { enqueueSnackbar } = useSnackbar()
  const { isGuestWithoutRegister } = useIsGuestWithoutRegister()
  const navigate = useNavigate()
  const { globalCode } = useGlobalContext()
  const { isRegisteredGuest } = useIsRegisteredGuest()
  const { selectedUser } = useUser()
  const fileUpload = useFileUpload(loggedUser?.id ?? '')

  // States and variables
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [filesPerPage, setFilesPerPage] = useState<number>(10)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [files, setFiles] = useState<ItemList<File>>(emptyList())
  const [countTotal, setCountTotal] = useState<number>(0)
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false)
  const [openModalMustRegister, setOpenModalMustRegister] =
    useState<boolean>(false)
  const [openPreviewGuest, setOpenPreviewGuest] = useState<boolean>(false)
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>(
    files.items.reduce<Record<string, boolean>>((acc, item) => {
      if (item?.id !== undefined) {
        acc[item.id] = false
      }
      return acc
    }, {}),
  )
  const [searcher, setSearcher] = useState<SearchValue<FileQuery>[]>([
    {
      name: 'name',
      label: t('search') + '...',
    },
  ])

  // Define if the user is a guest
  const isGuest = isRegisteredGuest || isGuestWithoutRegister

  // Functions
  const handleOpenModalMustRegister = () => {
    setOpenModalMustRegister(!openModalMustRegister)
  }

  const handleCheckedChange = (key: string) => {
    isGuest && setOpenPreviewGuest(true)
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [key]: !prevCheckedItems[key],
    }))
  }

  useEffect(() => {
    fileService.getAllByUserID(loggedUser?.id ?? '').subscribe((res) => {
      if (!res) return
      setCountTotal(res.items.length)
    })
  }, [files, isLoading, page])

  useEffect(() => {
    const searchTerms = [...searcherQuery(searcher)]
    if (searchTerms.length >= 1) return
    fileService
      .getByUserID(
        isRegisteredGuest ? (selectedUser?.id ?? '') : (loggedUser?.id ?? ''),
        new Query({
          pager: { limit: filesPerPage, offset: page * filesPerPage },
          sort: [{ field: 'date' }],
        }),
      )
      .subscribe((res) => {
        if (!res) return
        setCount(countTotal)
        setFiles(res)
      })
  }, [
    fileUpload.closePreview,
    isLoading,
    searcher,
    page,
    filesPerPage,
    countTotal,
  ])

  useEffect(() => {
    if (!searcher) {
      return
    }
    const searchTerms = [...searcherQuery(searcher)]
    const name = searchTerms.find((s) => s.value)
    if (name) {
      fileService
        .getFilteredList(
          new Query({
            pager: { limit: filesPerPage, offset: page * filesPerPage },
            query: [
              new QueryParam<FileQuery>('name', name.value),
              new QueryParam<FileQuery>('ownerID', loggedUser?.id ?? ''),
              ...searcherQuery(searcher),
            ],
            sort: [{ field: 'date', desc: true }],
          }),
        )
        .subscribe((res) => {
          setCount(countTotal)
          setFiles(res)
        })
    }
  }, [isSearch])

  const handlePaginationChange = (event: unknown, value: number) => {
    setPage(value)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (Number.isNaN(event.target.value)) {
      setFilesPerPage(10)
      return
    }
    setFilesPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsSearch(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: filesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, filesPerPage, countTotal])

  const search: Search<FileQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<FileQuery>[]) => {
      setSearcher(svs)
      setIsSearch(!isSearch)
    },
  }

  const handleCloseEdit = () => {
    setFiles(emptyList())
    setCheckedItems({})
    setOpenEdit(!openEdit)
    setIsLoading(!isLoading)
  }

  const handleCloseOpenPreviewGuest = () => {
    setFiles(emptyList())
    setCheckedItems({})
    setIsLoading(!isLoading)
    setOpenPreviewGuest(false)
  }

  const handleOpenModalDelete = () => {
    const selectedFiles = Object.keys(checkedItems).filter(
      (key) => checkedItems[key],
    )
    if (selectedFiles.length === 0) {
      enqueueSnackbar(t('selectFile'), { variant: 'warning' })
      return
    }
    setOpenModalDelete(!openModalDelete)
  }

  const handleOpenEdit = () => {
    const selectedFiles = Object.keys(checkedItems).filter(
      (key) => checkedItems[key],
    )
    if (selectedFiles.length === 0) {
      enqueueSnackbar(t('selectFile'), { variant: 'warning' })
      return
    }
    setOpenEdit(!openEdit)
  }

  const handleDelete = () => {
    const selectedFiles = Object.keys(checkedItems).filter(
      (key) => checkedItems[key],
    )
    if (selectedFiles.length === 0) {
      enqueueSnackbar(t('selectFile'), { variant: 'warning' })
      return
    }
    selectedFiles.forEach((key) => {
      const file = files.items.find((f) => f?.id === key)
      if (!file?.id) {
        return
      }
      fileService.delete(file.id).subscribe((res) => {
        if (!res) {
          enqueueSnackbar(t('anErrorHasOccurred'), { variant: 'error' })
          return
        }
        enqueueSnackbar(t('fileCorrectlyDeleted'), { variant: 'success' })
        setOpenModalDelete(!openModalDelete)
        setIsLoading(!isLoading)
        setCheckedItems({})
      })
    })
  }

  const handleRedirectToRegister = () => {
    navigate(`/register/${globalCode}`)
  }

  const getTypeIcon = (type: string) => {
    switch (type) {
      case FileType.PDF:
      case FileType.Docx:
      case FileType.Doc:
        return pdfIcon
      case FileType.Png:
      case FileType.Jpg:
        return imageIcon
      default:
        return pdfIcon
    }
  }

  const fields: Field<File>[] = [
    {
      name: 'date',
      label: t('date'),
      renderFunc: (f, i) => {
        return (
          <Typography className={styles.registrationDateText}>
            {new Date(i.date).toLocaleDateString()}
          </Typography>
        )
      },
    },
    {
      name: 'type',
      label: t('type'),
      renderFunc: (f, i) => {
        return (
          <img
            src={getTypeIcon(i.type)}
            alt={i.type}
            style={{ width: 30, height: 30 }}
          />
        )
      },
    },
    {
      name: 'ownerRole',
      label: t('dataSource'),
      renderFunc: (f, i) => {
        if (i.ownerRole === undefined) {
          return <p></p>
        }
        return <p>{i.ownerRole}</p>
      },
    },
    {
      name: 'name',
      label: t('fileName'),
    },
  ]

  return (
    <Box className={styles.container}>
      <GenericHeader title={'myMedicalHistory'} />
      {fileUpload.selectedFile && !fileUpload.closePreview && !openEdit ? (
        <UploadFile
          file={fileUpload.selectedFile}
          handlePreviewClose={fileUpload.handlePreviewClose}
          loggedUser={loggedUser}
          urlFile={fileUpload.urlFile ?? ''}
          fileChatbot={fileUpload.fileChatbot}
        />
      ) : openEdit || openPreviewGuest ? (
        <UploadFile
          id={Object.keys(checkedItems).filter((key) => checkedItems[key])[0]}
          file={fileUpload.selectedFile}
          handlePreviewClose={
            isGuest ? handleCloseOpenPreviewGuest : handleCloseEdit
          }
          loggedUser={loggedUser}
          urlFile={fileUpload.urlFile ?? ''}
          openPreviewGuest={openPreviewGuest}
        />
      ) : (
        <Box className={isGuest ? styles.guestContent : styles.content}>
          {isGuest && (
            <Box>
              <UserCard />
            </Box>
          )}
          <Box
            className={
              isGuest ? styles.appTableContainerGuest : styles.appTableContainer
            }
          >
            <ActionsComponent
              search={search}
              searchComponent={true}
              disabled={
                Object.keys(checkedItems).filter((key) => checkedItems[key])
                  .length > 1
              }
              handleOpenEdit={handleOpenEdit}
              handleDelete={handleOpenModalDelete}
              handleOpenModalRegister={handleOpenModalMustRegister}
              handleFileSelect={fileUpload.handleFileSelect}
            />
            <Box className={styles.tableContainer}>
              <AppTable
                fields={fields}
                items={files.items}
                rowKeyField={'id'}
                handlerChecked={handleCheckedChange}
                checked={checkedItems}
                pager={pager}
                isGuest={isGuest}
              />
            </Box>
          </Box>
        </Box>
      )}
      {openModalDelete && (
        <Modal open={openModalDelete}>
          <>
            <CustomModal
              title={t('wantDeleteFile')}
              handleClose={handleOpenModalDelete}
            >
              <Box mt={1} mb={1} className={style.buttons}>
                <Box style={{ marginRight: 4 }}>
                  <ButtonCancel
                    className={style.buttonCancel}
                    type={'button'}
                    onClick={handleOpenModalDelete}
                  >
                    {t('no')}
                  </ButtonCancel>
                </Box>
                <ButtonAccept type={'button'} onClick={handleDelete}>
                  {t('yes')}
                </ButtonAccept>
              </Box>
            </CustomModal>
          </>
        </Modal>
      )}
      {openModalMustRegister && (
        <Modal open={openModalMustRegister}>
          <>
            <CustomModal
              title={t('mustRegister')}
              handleClose={handleOpenModalMustRegister}
            >
              <Box
                mt={3}
                mb={1}
                className={style.buttons}
                display={'flex'}
                justifyContent={'center'}
              >
                <ButtonRegisterOrLogin
                  type={'button'}
                  onClick={handleRedirectToRegister}
                >
                  {t('registerOrLogin')}
                </ButtonRegisterOrLogin>
              </Box>
            </CustomModal>
          </>
        </Modal>
      )}
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<FileQuery>[],
): QueryParam<FileQuery>[] | QueryParamN<FileQuery>[] =>
  svs
    .filter((sv) => sv.value)
    .map((sv) => ({ name: sv.name, value: sv.value as string }))
