import { SurveyQuestion } from './SurveyQuestion'
import { v4 as uuidv4 } from 'uuid'

export interface SurveyQuestionDTO {
  id?: string
  question: string
  index: number
  selected: string
}

export function emptySurveyQuestionDTO(): SurveyQuestionDTO {
  return {
    id: uuidv4(),
    question: '',
    index: 0,
    selected: '',
  }
}

export function fromModel(f: SurveyQuestion): SurveyQuestionDTO {
  return {
    id: f.id,
    question: f.question,
    index: f.index,
    selected: f.selected,
  }
}

export function toModel(d: SurveyQuestionDTO): SurveyQuestion {
  return new SurveyQuestion(d)
}
