import useLoggedInUser from './useLoggedInUser'
import { RoleType } from '../modules/users/enums/RoleEnum'

const useIsAdmin = () => {
  const { user } = useLoggedInUser()
  const isAdmin = user?.roles.toString() === RoleType.SystemAdmin.toString()

  return {
    isAdmin,
  }
}

export default useIsAdmin
