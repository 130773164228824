import { USERS_MODULE } from '../modules/users'
import { FILES_MODULE } from '../modules/files'
import { ACTIVITY_MODULE } from '../modules/activity'
import { INVITATION_MODULE } from '../modules/invitation '
import { CHATBOT_MODULE } from '../modules/chatbot'
import { SURVEY_QUESTION_MODULE } from '../modules/survey'

// const apiURL = 'http://localhost:8000'
const apiURL = 'https://staging.aria-healthcare.com/backend/'

export const moduleURLs = {
  [USERS_MODULE]: `${apiURL}/users`,
  [FILES_MODULE]: `${apiURL}/files`,
  [ACTIVITY_MODULE]: `${apiURL}/activity`,
  [INVITATION_MODULE]: `${apiURL}/invitation`,
  [SURVEY_QUESTION_MODULE]: `${apiURL}/survey`,
  [CHATBOT_MODULE]: `${process.env.REACT_APP_CHATBOT_URL}`,
}
